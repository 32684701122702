import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { emitChat } from '../services/socket';
import { useDispatch, useSelector } from 'react-redux';
import { messageService } from '../services/message';
import moment from 'moment';
import { parseAvatar, request } from '../services/utilities';
import { setChatMessageRead, setSupportChats } from '../actions/chat';
import { chatAPI } from '../services/constants';

const FloatedChat = () => {
	const dispatch = useDispatch();
	const [chatOpen, setChatOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [chatGroup, setChatGroup] = useState([]);
	const profile = useSelector(state => state.user.profile);
	const supportChats = useSelector(state => state.chat.supportChats);
	const chatContainerRef = useRef(null);

	const handleClick = () => {
		setChatOpen(!chatOpen);
	};

	const handleSendMessage = e => {
		e.preventDefault();
		if (message) {
			emitChat('send_support', {
				sender_id: profile?.details?.id,
				body: message,
			});

			setMessage('');
		}
	};

	const fetchSupportChats = useCallback(async () => {
		const url = chatAPI + 'fetch/support/messages';

		const response = await request(url, 'GET', true);
		const { messages } = response;

		dispatch(setSupportChats(messages));
	}, [chatOpen, dispatch]);

	useEffect(() => {
		if (chatOpen) {
			fetchSupportChats();
		}
	}, [chatOpen, fetchSupportChats]);

	// scroll and mark as read
	useEffect(() => {
		if (chatContainerRef.current && chatGroup.length > 0 && chatOpen) {
			chatContainerRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'end',
			});

			const unreadChatIds = supportChats.filter(chat => !chat.is_read).map(chat => chat.id);

			// console.log(unreadChatIds);

			if (unreadChatIds.length) {
				emitChat('mark_read', {
					chat_ids: unreadChatIds,
				});

				dispatch(setChatMessageRead());
			}
		}
	}, [chatGroup, dispatch]);

	// chat socket subscription
	useEffect(() => {
		const subscription = messageService.getMessage().subscribe(message => {
			const { type, data } = message.text;
			if (type === 'new-support-reply-message') {
				const date = new Date().toDateString();
				data.createdAt = new Date();

				const temp = [...chatGroup];
				const index = temp.findIndex(group => group.date === date);
				if (index === -1) {
					temp.push({
						date,
						chats: [data],
					});
				} else {
					temp[index].chats.push(data);
				}

				setChatGroup(temp);
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	});

	useEffect(() => {
		if (supportChats.length) {
			const groups = {};

			supportChats.forEach(chat => {
				const date = new Date(chat.createdAt).toDateString();
				if (!groups[date]) {
					groups[date] = [];
				}

				groups[date].push(chat);
			});

			const sortedGroups = Object.keys(groups)
				.map(key => {
					return {
						date: key,
						chats: groups[key],
					};
				})
				.sort((a, b) => {
					return new Date(a.date) - new Date(b.date);
				});

			setChatGroup(sortedGroups);
		}
	}, [supportChats]);

	return (
		<Fragment>
			<div className="floated-chat-btn" onClick={handleClick}>
				<i className="os-icon os-icon-mail-07" />
				<span>Support</span>
			</div>
			{chatOpen && (
				<div className={`floated-chat-w ${chatOpen && 'active'} `}>
					<div className="floated-chat-i">
						<div className="chat-close" onClick={handleClick}>
							<i className="os-icon os-icon-close" />
						</div>
						<div className="chat-head">
							<div className="user-w with-status status-green">
								<div className="user-avatar-w">
									<div className="user-avatar">
										<img alt="" src={parseAvatar('')} />
									</div>
								</div>
								<div className="user-name">
									<h6 className="user-title">IT Support</h6>
									{/* <div className="user-role">Account Manager</div> */}
								</div>
							</div>
						</div>
						<div className="chat-messages">
							{chatGroup.length === 0 ? (
								<div className="text-center">No message</div>
							) : (
								chatGroup.map((group, index) => {
									const date = moment(new Date(group.date));
									const now = moment();
									const diff = now.diff(date, 'days');

									let groupedDate;
									if (diff === 0) {
										groupedDate = 'Today';
									} else if (diff === 1) {
										groupedDate = 'Yesterday';
									} else {
										groupedDate = group.date;
									}
									return (
										<div ref={chatContainerRef} key={index + group.date}>
											<div className="date-break">{groupedDate}</div>
											{group.chats.map((chat, index) => (
												<div key={index + chat.body} className={`message ${chat.sender_id === profile?.details?.id && 'self'}`}>
													<div className="message-content">{chat.body}</div>
												</div>
											))}
										</div>
									);
								})
							)}
						</div>
						<div className="chat-controls">
							<form onSubmit={handleSendMessage}>
								<input
									className="message-input"
									placeholder="Type your message here..."
									type="text"
									value={message}
									onChange={e => setMessage(e.target.value)}
								/>
								<div className="chat-extra d-flex justify-content-end">
									<button className="bg-transparent p-0 border-0" type="submit">
										<a>
											<span className="extra-tooltip">Send</span>
											<i className="os-icon os-icon-mail-18" />
										</a>
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default FloatedChat;
