import React, { useState } from 'react';
import { notifyError, notifySuccess } from '../../services/notify';
import { request } from '../../services/utilities';
import waiting from '../../assets/images/waiting.gif';

export const SendUpdateLink = ({ closeModal, patient, updatePatient }) => {
	const [submitting, setSubmitting] = useState(false);
	const [email, setEmail] = useState(patient?.email);
	const [phone, setPhone] = useState(patient?.phone_number);

	const SendLink = async (id, newEmail) => {
		try {
			const uri = `patient/generate/online/profile`;

			if (!newEmail || !newEmail.split('').includes('@')) {
				notifyError('Email is required!');
				return;
			}
			setSubmitting(true);
			let rs = await request(uri, 'POST', true, { pid: id, email: newEmail, phone_number: phone });
			if (rs.success) {
				updatePatient(rs.patient);
				closeModal();
				notifySuccess('Update link sent!');
				setSubmitting(false);
			} else {
				setSubmitting(false);
				notifyError(rs.message || 'Profile generation failed!');
			}
		} catch (error) {
			setSubmitting(false);
			console.log(error);
			notifyError('Error sending email');
		}
	};

	return (
		<div className="onboarding-modal modal fade animated show" role="dialog" style={{ display: 'block' }}>
			<div className="modal-dialog modal-centered" style={{ maxWidth: '320px' }}>
				<div className="modal-content text-center">
					<button aria-label="Close" className="close" type="button" onClick={closeModal}>
						<span className="os-icon os-icon-close" />
					</button>
					<div className="onboarding-content with-gradient">
						<h4 className="onboarding-title">Generate Online Profile</h4>
						<div className="form-block">
							<form
								onSubmit={e => {
									e.preventDefault();
									SendLink(patient?.id, email);
								}}
							>
								<div style={{ height: '20px' }}>
									{(!email || !email.split('').includes('@')) && <label style={{ color: 'red' }}>Email is required</label>}
								</div>
								<div className="row my-2">
									<div className="col-sm-12">
										<label>
											Email<small className="text-danger">*</small>{' '}
										</label>
										<input
											className="form-control"
											type="email"
											value={email}
											name="email"
											required
											onChange={e => {
												const val = e.target.value;
												setEmail(val);
											}}
										/>
									</div>
								</div>
								<div className="row my-2">
									<div className="col-sm-12">
										<label>Phone Number</label>
										<input
											className="form-control"
											type="text"
											name="phone"
											value={phone}
											onChange={e => {
												const val = e.target.value;
												setPhone(val);
											}}
										/>
									</div>
								</div>
								<div className="row mt-4">
									<div className="col-sm-12 text-right">
										<button className="mx-2 btn btn-secondary" type="button" onClick={closeModal} disabled={!email}>
											Close
										</button>
										<button className="btn btn-primary" type="submit" disabled={!email}>
											{submitting ? <img src={waiting} alt="submitting" /> : 'Send'}
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
