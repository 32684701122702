import React, { useState } from 'react';
import FormWizard from '../FormWizard';
import { ErrorBlock, request, removeNullData } from '../../services/utilities';
import { Field } from 'react-final-form';
import { notifyError, notifySuccess } from '../../services/notify';
import { days } from '../../services/constants';

const ViewEditChart = ({ closeModal, stimulation, fetchChartData, data, fetchStimulationData }) => {
	const [day, setDay] = useState('');
	const [leftValue, setLeftValue] = useState([]);
	const [rightValue, setRightValue] = useState([]);
	const [endo, setEndo] = useState('');
	const [error, setError] = useState('');

	// for left or right ovaries
	const [measurement, setMeasurement] = useState('');
	const [totalFollicles, setTotalfolicles] = useState('');

	const initialValues = {
		endo: '',
		day: '',
	};

	const handleFieldChange = (e, type = null) => {
		if (!type) {
			setEndo(e.target.value);
		} else {
			if (e) {
				setDay(e.target.value);
			}
			if (data.right[`${e.target.value}`]) {
				let r = data.right[`${e.target.value}`];
				setRightValue(r);
			} else {
				setRightValue([]);
			}
			if (data.left[`${e.target.value}`]) {
				let l = data.left[`${e.target.value}`];
				setLeftValue(l);
			} else {
				setLeftValue([]);
			}
			if (data.right[`endo_${e.target.value}`]) {
				let en = data.right[`endo_${e.target.value}`].replace(/\D+/g, '');
				setEndo(en);
			} else if (data.right[`endo_${e.target.value}`]) {
				let en = data.left[`endo_${e.target.value}`].replace(/\D+/g, '');
				setEndo(en);
			} else {
				setEndo('');
			}
		}
	};

	const pushToLeft = () => {
		const newLeftOvary = [
			...leftValue,
			{
				totalFollicles: parseInt(totalFollicles, 10),
				measurement: parseInt(measurement, 10),
			},
		];

		setLeftValue(newLeftOvary);
		setTotalfolicles('');
		setMeasurement('');
	};

	// Push To Right Ovary
	const pushToRight = () => {
		const newRightOvary = [
			...rightValue,
			{
				totalFollicles: parseInt(totalFollicles, 10),
				measurement: parseInt(measurement, 10),
			},
		];

		setRightValue(newRightOvary);
		setTotalfolicles('');
		setMeasurement('');
	};

	const removeFromLeft = index => {
		const newLeftValue = leftValue.filter((_val, i) => index !== i);

		setLeftValue(newLeftValue);
	};
	const removeFromRight = index => {
		const newRightValue = rightValue.filter((_val, i) => index !== i);

		setRightValue(newRightValue);
	};

	const onSubmit = async () => {
		const data = {
			stimulation_id: stimulation.id,
			day,
			left: leftValue,
			right: rightValue,
			endo,
		};

		if (!day) {
			setError('please select day');
			return;
		}
		if (!endo) {
			setError('please select endo');
			return;
		}
		if (endo && !Number.isInteger(parseInt(endo, 10))) {
			setError('endo value must be a number');
			return;
		}
		if (endo && parseInt(endo, 10) > 28) {
			setError('endo value must be less than 28');
			return;
		}

		try {
			const url = 'ivf/stimulation/data';
			const rs = await request(url, 'POST', true, data);
			console.log(rs);

			if (rs.success) {
				notifySuccess('stimulation Saved Successful!');
				closeModal();
				fetchChartData();
				fetchStimulationData();
			} else {
				notifyError('Failed to save Stimulation Data!');
				closeModal();
			}
		} catch (error) {
			console.log(error);
			notifyError('Failed to save Stimulation Data!');
			closeModal();
		}
	};

	return (
		<div className="onboarding-modal modal fade animated show" role="dialog" style={{ display: 'block' }}>
			<div className="modal-dialog modal-content modal-centered modal-scroll" style={{ maxWidth: '1024px' }}>
				<div className="modal-content text-center">
					<button aria-label="Close" className="close" type="button" onClick={closeModal}>
						<span className="os-icon os-icon-close"></span>
					</button>
					<div className="onboarding-content with-gradient">
						<h4 className="onboarding-title">Edit Stimulation</h4>
						<div className="form-block element-box">
							<FormWizard
								initialValues={Object.fromEntries(Object.entries(initialValues).filter(([_, v]) => v !== ''))}
								onSubmit={onSubmit}
								btnClasses="modal-footer buttons-on-right"
							>
								<FormWizard.Page>
									<div className="row">
										<div className="col-sm-6">
											<div className="form-group">
												<label>DAY</label>
												<select
													name="day"
													id="day"
													value={day}
													className="form-control"
													onChange={e => {
														handleFieldChange(e, 'day');
													}}
												>
													<option value="">select day</option>
													{days.map(day => (
														<option value={day.id}>{day.name}</option>
													))}
												</select>
												<ErrorBlock name="day" />
											</div>
										</div>
										<div className="col-sm-6">
											<div className="form-group">
												<label>ENDO (mm)</label>
												<Field name="endo" className="form-control" component="input" type="text">
													{({ input }) => <input {...input} type="text" value={endo} className="form-control" onChange={handleFieldChange} />}
												</Field>
												<ErrorBlock name="endo" />
											</div>
										</div>
									</div>
									{/* new Row starts */}
									<div className="row">
										<div className="col-sm-2">
											<div className="form-group">
												<label>Number of Folicles</label>
												<input className="form-control" type="text" value={totalFollicles} onChange={e => setTotalfolicles(e.target.value)} />
												{/* <ErrorBlock name="right" /> */}
											</div>
										</div>
										<div className="col-sm-2">
											<div className="form-group">
												<label>Measurement (mm)</label>
												<input className="form-control" type="text" value={measurement} onChange={e => setMeasurement(e.target.value)} />
												{/* <ErrorBlock name="right" /> */}
											</div>
										</div>
										<div className="col-sm-3 mt-4">
											<div className="form-group">
												<a className="btn btn-sm btn-success btn-upper text-white" onClick={pushToLeft}>
													<i className="os-icon os-icon-plus" />
													<span>ADD TO LEFT</span>
												</a>
											</div>
										</div>
										<div className="col-sm-3 mt-4">
											<div className="form-group">
												<a className="btn btn-sm btn-success btn-upper text-white" onClick={pushToRight}>
													<i className="os-icon os-icon-plus" />
													<span>ADD TO RIGHT</span>
												</a>
											</div>
										</div>

										{/* End of row for Left and Right data */}
									</div>
									{/* Display data added to left or right */}
									<div className="row">
										<div className="col-sm-6">
											<table className="table table-striped table-bordered">
												<thead>
													<tr>
														<th>No. of Follicles</th>
														<th>Measurement (mm)</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{leftValue.map((data, i) => (
														<tr key={i}>
															<td>{data?.totalFollicles}</td>
															<td>{data?.measurement}</td>
															<td className="flex flex-row">
																<a className="btn btn-sm btn-danger btn-upper text-white m-0" onClick={() => removeFromLeft(i)}>
																	<i className="os-icon os-icon-trash" />
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										<div className="col-sm-6">
											<table className="table table-striped table-bordered">
												<thead>
													<tr>
														<th>No. of Follicles</th>
														<th>Measurement (mm)</th>
													</tr>
												</thead>
												<tbody>
													{rightValue.map((data, i) => (
														<tr key={i}>
															<td>{data?.totalFollicles}</td>
															<td>{data?.measurement}</td>
															<td className="flex flex-row">
																<a className="btn btn-sm btn-danger btn-upper text-white m-0" onClick={() => removeFromRight(i)}>
																	<i className="os-icon os-icon-trash" />
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>
								</FormWizard.Page>
							</FormWizard>
							<p id="error" className="text-danger">
								{error}
							</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewEditChart;
