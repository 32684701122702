import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { hasCreateLabPermission } from '../../permission-utils/lab';

const ClinicalLabMenu = () => {
	const staff = useSelector(state => state.user.profile);

	const canViewLabReports = user => {
		switch (user.role.slug) {
			case 'lab-manager':
				return true;
			case 'lab-supervisor':
				return true;
			default:
				return false;
		}
	};

	return (
		<>
			<li>
				<Link to="/lab">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Dashboard</span>
				</Link>
			</li>
			{hasCreateLabPermission(staff.permissions) && (
				<li>
					<Link to="/lab/new-request">
						<div className="icon-w">
							<div className="os-icon os-icon-plus-circle" />
						</div>
						<span>New Lab Request</span>
					</Link>
				</li>
			)}
			{canViewLabReports(staff) && (
				<>
					<li className="sub-header">
						<span>REPORTS</span>
					</li>
					<li>
						<Link to="/report/lab">
							<div className="icon-w">
								<div className="os-icon os-icon-layers" />
							</div>
							<span>Lab</span>
						</Link>
					</li>
				</>
			)}
			{/* <li>
				<Link to="/lab/admitted-lab">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Admission Bills</span>
				</Link>
			</li> */}
		</>
	);
};

export default ClinicalLabMenu;
