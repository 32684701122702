import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { connect, useDispatch } from 'react-redux';
import { patientAPI } from '../../services/constants';
import { loadVitals } from '../../actions/patient';
import { formatDate, request } from '../../services/utilities';
import TableLoading from '../TableLoading';

const info = ['Blood Pressure', 'Temperature', 'Fetal Heart Rate', 'Respiration', 'Glucose', 'Dilation', 'Servico Graph', 'Contractions'];

const Pathograph = ({ patient, labourId, vitals }) => {
	const dispatch = useDispatch();

	const [isMounted, setisMounted] = useState(false);
	const [data, setData] = useState([]);

	const fetchVitals = useCallback(async () => {
		const url = `${patientAPI}/${patient.id}/vitals?labour_id=${labourId}`;
		const rs = await request(url, 'GET', true);
		dispatch(loadVitals(rs.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))));
		setisMounted(true);
	}, [dispatch, labourId, patient.id]);

	const formatVitals = () => {
		try {
			let data = [];

			const cloneVitals = [...vitals];
			cloneVitals
				.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
				.forEach((item, index) => {
					const date = formatDate(item.createdAt, 'DD-MMM-YYYY h:mma');
					const res = { ...item.reading, name: date };
					data = [...data, res];
				});

			setData(data);
		} catch (e) {}
	};

	useEffect(() => {
		fetchVitals();
	}, []);

	useEffect(() => {
		if (isMounted) {
			formatVitals();
		}
	}, [isMounted]);

	const options = {
		chart: {
			id: 'basic-bar',
		},
		markers: {
			size: 4,
		},
		stroke: {
			width: [2, 2, 2, 2, 2],
			curve: 'straight',
		},
		colors: ['#5b73e8', '#ff0000', '#00ffff', '#00008B', '#A52A2A', '#C0C0C0', '#a77fb3', '#7d4737', '#ff8241', '#ff2221'],
		// xaxis: {
		// 	categories: data?.map(d => d.name),
		// 	labels: {
		// 		style: {
		// 			fontSize: '10px',
		// 			fontFamily: 'trebuchet',
		// 			fontWeight: 400,
		// 			cssClass: 'apexcharts-xaxis-label',
		// 		},
		// 	},
		// },
		yaxis: {
			title: {
				text: `Partograph `,
			},
		},
		grid: {
			borderColor: '#f1f1f1',
		},
	};

	const series = [
		{
			name: 'FETAL HEART RATE',
			type: 'line',
			data: data
				?.map(d => d.fetal_heart_rate)
				.filter(item => item !== undefined)
				.slice(-20),
		},
		{
			name: 'RESPIRATION',
			type: 'line',
			data: data
				?.map(d => d.respiration)
				.filter(item => item !== undefined)
				.map(d => d * 10),
		},
		{
			name: 'TEMPRATURE',
			type: 'line',
			data: data
				?.map(d => d.temperature)
				.filter(item => item !== undefined)
				.slice(-20)
				.map(d => d * 10),
		},
		{
			name: 'DILATION',
			type: 'line',
			data: data
				?.map(d => d.dilation)
				.filter(item => item !== undefined)
				.map(d => d * 10),
		},
		{
			name: 'CONTRACTIONS',
			type: 'line',
			data: data
				?.map(d => d.contractions)
				.filter(item => item !== undefined)
				.slice(-20)
				.map(item => item.split('/'))
				.map(item => item[0] * 10),
		},
		{
			name: 'GLUCOSE',
			type: 'line',
			data: data?.map(d => d.glucose).filter(item => item !== undefined),
		},
		{
			name: 'BP SYSTOLIC',
			type: 'line',
			data: data
				?.map(d => d.blood_pressure)
				.filter(item => item !== undefined)
				.slice(-20)
				.map(item => item.split('/'))
				.map(item => item[0]),
		},
		{
			name: 'BP DIASTOLIC',
			type: 'line',
			data: data
				?.map(d => d.blood_pressure)
				.filter(item => item !== undefined)
				.slice(-20)
				.map(item => item.split('/'))
				.map(item => item[1]),
		},
	];

	return (
		<div className="col-sm-12">
			<div className="element-wrapper">
				<h6 className="element-header">Partograph </h6>
				<div className="element-box p-3 m-0">
					{!isMounted ? (
						<TableLoading />
					) : (
						<div className="el-chart-w">
							<ReactApexChart options={options} series={series} height="339" type="line" className="apex-charts" />
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		vitals: state.patient.vitals.filter(c => info.includes(c.readingType)),
	};
};

export default connect(mapStateToProps)(Pathograph);
