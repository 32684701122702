import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const MyAccount = () => {
	const role = useSelector(state => state.user.profile?.role.slug);
	const department = useSelector(
		state => state.user.profile?.details?.department?.name
	);
	return (
		<>
			<li className="sub-header">
				<span>MY ACCOUNT</span>
			</li>
			{/* {role === 'it-admin' && department == 'ICT' && ( */}
			<li>
				<Link to="/my-account/chat">
					<div className="icon-w">
						<div className="os-icon os-icon-newspaper" />
					</div>
					<span>Chat</span>
				</Link>
			</li>
			{/* )} */}
			<li>
				<Link to="/my-account/payslips">
					<div className="icon-w">
						<div className="os-icon os-icon-newspaper" />
					</div>
					<span>Payslips</span>
				</Link>
			</li>
			<li>
				<Link to="/my-account/duty-roster">
					<div className="icon-w">
						<div className="os-icon icon-feather-calendar" />
					</div>
					<span>Duty Roster</span>
				</Link>
			</li>
			<li>
				<Link to="/my-account/appraisals">
					<div className="icon-w">
						<div className="os-icon os-icon-bar-chart-stats-up" />
					</div>
					<span>Appraisal</span>
				</Link>
			</li>
			<li>
				<Link to="/my-account/leave-requests">
					<div className="icon-w">
						<div className="os-icon os-icon-agenda-1" />
					</div>
					<span>Leave Request</span>
				</Link>
			</li>
			<li>
				<Link to="/my-account/my-attendance">
					<div className="icon-w">
						<div className="os-icon os-icon-list" />
					</div>
					<span>My Attendance</span>
				</Link>
			</li>
			<li>
				<Link to="/my-account/requisitions">
					<div className="icon-w">
						<div className="os-icon os-icon-agenda-1" />
					</div>
					<span>Requisitions</span>
				</Link>
			</li>
		</>
	);
};

export default MyAccount;
