/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import Tooltip from 'antd/lib/tooltip';
import { formatCurrency, staffname } from '../services/utilities';
import { viewPayrollHistory, viewCurrentPayroll, viewEditPayroll } from '../actions/general';

const PayrollItem = ({ item, isChecked, setChecked }) => {
	const date = moment(item.period, 'YYYY-MM');

	const doViewPayroll = e => {
		e.preventDefault();

		const staff = { id: item.staff.id };
		viewPayrollHistory(true, staff);
	};

	const doViewCurrentPayroll = e => {
		e.preventDefault();

		viewCurrentPayroll(true, item.id);
	};

	const doEditPayroll = e => {
		e.preventDefault();
		viewEditPayroll(true, true, item.id);
	};

	return (
		<tr>
			<td>
				<input
					value={item.staff.id}
					checked={!!isChecked}
					type="checkbox"
					onChange={e => setChecked(e.target.checked, parseInt(e.target.value, 10))}
				/>
			</td>
			<td>#{item.id}</td>
			<td>{staffname(item.staff)}</td>
			<td>{formatCurrency(item.salary_amount)}</td>
			<td>{formatCurrency(item.total_allowance)}</td>
			<td>{formatCurrency(item.total_deduction)}</td>
			<td>{formatCurrency(item.amount_paid)}</td>
			<td>{item.department.name}</td>
			<td>{date.format('MMMM')}</td>
			<td>{date.format('YYYY')}</td>
			<td>{moment(item.createdAt).format('D MMM, YYYY')}</td>
			<td className="row-actions">
				{item.status !== 1 && (
					<Tooltip title={'Edit Payslip'}>
						<a onClick={doEditPayroll} className="primary">
							<i className="os-icon os-icon-edit-1" />
						</a>
					</Tooltip>
				)}
				<Tooltip title={'View Payslip'}>
					<a onClick={doEditPayroll} className="secondary">
						<i className="os-icon os-icon-eye" />
					</a>
				</Tooltip>
			</td>
		</tr>
	);
};

export default PayrollItem;
