import React from 'react';
import { Link } from 'react-router-dom';

const ReportMenu = () => {
	return (
		<>
			<li>
				<Link to="/report/frontdesk/registration">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Front Desk</span>
				</Link>
			</li>
			<li>
				<Link to="/report/doctor">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Doctor</span>
				</Link>
			</li>
			<li>
				<Link to="/report/pharmacy">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Pharmacy</span>
				</Link>
			</li>
			{/* <li>
				<Link to="/report/nursing">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Nursing</span>
				</Link>
			</li> */}
			<li>
				<Link to="/report/lab">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Lab</span>
				</Link>
			</li>
			<li>
				<Link to="/report/income">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Income</span>
				</Link>
			</li>
			<li>
				<Link to="/report/cafeteria">
					<div className="icon-w text-danger">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Cafeteria</span>
				</Link>
			</li>
			{/* <li>
				<Link to="/report/payable">
					<div className="icon-w text-danger">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Payables</span>
				</Link>
			</li> */}
			<li>
				<Link to="/report/human-resources">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Human Resources</span>
				</Link>
			</li>
			<li>
				<Link to="/report/nicu">
					<div className="icon-w">
						<div className="icon-feather-folder-plus" />
					</div>
					<span>Nicu</span>
				</Link>
			</li>
			<li>
				<Link to="/report/admissions">
					<div className="icon-w">
						<div className="icon-feather-folder-plus" />
					</div>
					<span>Admissions</span>
				</Link>
			</li>
			<li>
				<Link to="/report/procedures">
					<div className="icon-w text-danger">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Theatre</span>
				</Link>
			</li>
			<li>
				<Link to="/report/ivf">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>IVF Reports</span>
				</Link>
			</li>
			{/* <li>
				<Link to="/report/others">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Others</span>
				</Link>
			</li> */}
		</>
	);
};

export default ReportMenu;
