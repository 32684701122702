import React, { useEffect, useState } from 'react';
import ChartForm from './ChartForm';
import { notifyError } from '../../services/notify';
import { request } from '../../services/utilities';
import ViewEditChart from './ViewEditChart';
import TableLoading from '../TableLoading';
import { isEmpty } from 'lodash';

import EndoChart from './StimulationCharts/EndoChart';
import RightOvaryChart from './StimulationCharts/RightOvaryChart';
import LeftOvaryChart from './StimulationCharts/LeftOvaryChart';

const StimulationChart = ({ patient, ivf }) => {
	const [loaded, setLoaded] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [chart, setChart] = useState([]);
	const [mydata, setData] = useState([]);
	const [showModal, setShowModal] = useState(false);
	const [showView, setShowView] = useState(false);
	const [result, setResult] = useState({});
	const [activePage, setActivePage] = useState('Endo');

	const fetchStimulationData = async () => {
		try {
			setDataLoaded(false);
			const url = `ivf/stimulation/data?enrollment_id=${ivf.id}`;
			const rs = await request(url, 'GET', true);
			if (rs?.success) {
				setResult(rs);
			}
			console.log(rs, 'result');
			setDataLoaded(true);
		} catch (error) {
			setDataLoaded(true);
			console.log(error);
			notifyError('error fetching chart data');
		}
	};

	const fetchChartData = async () => {
		try {
			setLoaded(false);
			const url = `ivf/stimulation?enrollment_id=${ivf.id}&patient_id=${patient.id}&limit=31`;
			const rs = await request(url, 'GET', true);
			if (rs.success) {
				setChart(rs.result);
				setData(rs?.result[0]?.data);
				setLoaded(true);
			}
			setLoaded(true);
		} catch (error) {
			console.log(error);
			setLoaded(true);
			notifyError('error fetching chart data');
		}
	};

	const viewandEdit = () => {
		document.body.classList.add('modal-open');
		setShowView(true);
	};

	const newEntry = () => {
		document.body.classList.add('modal-open');
		setShowModal(true);
	};

	const closeModal = () => {
		document.body.classList.remove('modal-open');
		setShowModal(false);
		setShowView(false);
	};

	useEffect(() => {
		if (!loaded) {
			fetchChartData();
		}
		if (!dataLoaded) {
			fetchStimulationData();
		}
	}, []);

	return (
		<div className="col-sm-12">
			{!loaded ? (
				<TableLoading />
			) : (
				<div className="element-wrapper">
					{/* NavBars */}
					<div className="os-tabs-w mx-1">
						<div className="os-tabs-controls os-tabs-complex ">
							<ul className="nav nav-tabs upper justify-content-center ">
								<button
									type="button"
									className={`btn  ${activePage === 'Endo' ? 'btn-primary' : 'btn-secondary'}  btn-sm`}
									onClick={() => {
										setActivePage('Endo');
									}}
								>
									Endo
								</button>
								<button
									type="button"
									className={`btn  ${activePage === 'Left-ovary' ? 'btn-primary' : 'btn-secondary'}  btn-sm`}
									onClick={() => {
										setActivePage('Left-ovary');
									}}
								>
									Left Ovary
								</button>
								<button
									type="button"
									className={`btn  ${activePage === 'Right-ovary' ? 'btn-primary' : 'btn-secondary'}  btn-sm`}
									onClick={() => {
										setActivePage('Right-ovary');
									}}
								>
									Right Ovary
								</button>
								{/* <button
									type="button"
									className={`btn  ${activePage === 'Summary' ? 'btn-primary' : 'btn-secondary'}  btn-sm`}
									onClick={() => {
										setActivePage('Summary');
									}}>
									Summary
								</button> */}
							</ul>
						</div>
					</div>
					<div className="element-actions flex-action">
						{isEmpty(chart) ? (
							<a className="btn btn-sm btn-secondary text-white ml-3" onClick={() => newEntry()}>
								New Stimulation
							</a>
						) : (
							<a className="btn btn-sm btn-secondary text-white ml-3" onClick={() => viewandEdit()}>
								View Stimulation
							</a>
						)}
					</div>
					<div className="row vital">
						<div className={activePage === 'Endo' ? 'col-12' : ''} style={{ maxWidth: '100%' }}>
							{activePage === 'Endo' && <EndoChart mydata={mydata} />}
							{activePage === 'Right-ovary' && <RightOvaryChart stimulationId={chart[0]?.id} />}
							{activePage === 'Left-ovary' && <LeftOvaryChart stimulationId={chart[0]?.id} />}
						</div>
					</div>
				</div>
			)}
			{showModal && <ChartForm closeModal={closeModal} patient_id={patient.id} ivfId={ivf.id} fetchChartData={fetchChartData} />}

			{showView && (
				<ViewEditChart
					closeModal={closeModal}
					stimulation={chart[0]}
					fetchChartData={fetchChartData}
					fetchStimulationData={fetchStimulationData}
					data={result}
				/>
			)}
		</div>
	);
};

export default StimulationChart;
