// general
export const CREAE_NEW_DRUG = 'CREAE_NEW_DRUG';
export const TOGGLE_CONNECTED = 'TOGGLE_CONNECTED';
export const REQUEST_START = 'REQUEST_START';
export const REQUEST_STOP = 'REQUEST_STOP';
export const TOGGLE_PRELOADING = 'TOGGLE_PRELOADING';
export const TOGGLE_IS_MODAL = 'TOGGLE_IS_MODAL';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const TOGGLE_VIEW_PAYROLL_HISTORY = 'TOGGLE_VIEW_PAYROLL_HISTORY';
export const TOGGLE_VIEW_CURRENT_PAYROLL = 'TOGGLE_VIEW_CURRENT_PAYROLL';
export const TOGGLE_EDIT_PAYROLL = 'TOGGLE_EDIT_PAYROLL';
export const SET_PATIENT_RECORD = 'SET_PATIENT_RECORD';
export const LOAD_PAYMENT_METHODS = 'LOAD_PAYMENT_METHODS';
export const CREATE_NEW_GENERIC = 'CREATE_NEW_GENERIC';
export const TOGGLE_CHAT_INBOX = 'TOGGLE_CHAT_INBOX';
export const TOGGLE_SIDEPANEL = 'TOGGLE_SIDEPANEL';
export const SET_PATIENT_DATA = 'SET_PATIENT_DATA';
export const TOGGLE_SESSION_TIMEOUT = 'TOGGLE_SESSION_TIMEOUT';

// user
export const SET_PROFILE = 'SET_PROFILE';
export const SIGN_OUT = 'SIGN_OUT';
export const TOGGLE_MODE = 'TOGGLE_MODE';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const TOGGLE_FULLSCREEN = 'TOGGLE_FULLSCREEN';
export const INIT_MODE = 'INIT_MODE';
export const INIT_FULLSCREEN = 'INIT_FULLSCREEN';
export const TOGGLE_PROFILE = 'TOGGLE_PROFILE';
export const UPDATE_STAFF = 'UPDATE_STAFF';

// role
export const LOAD_ROLES = 'LOAD_ROLES';
export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';

// hr
export const LOAD_APPRAISALS = 'LOAD_APPRAISALS';
export const LOAD_PAYROLL = 'LOAD_PAYROLL';
export const LOAD_UNPAID_PAYROLL = 'LOAD_UNPAID_PAYROLL';
export const LOAD_PAYROLL_HISTORY = 'LOAD_PAYROLL_HISTORY';
export const ADD_PERFORMANCE_PERIOD = 'ADD_PERFORMANCE_PERIOD';
export const LOAD_PERFORMANCE_PERIOD = 'LOAD_PERFORMANCE_PERIOD';
export const SET_PERFORMANCE_PERIOD = 'SET_PERFORMANCE_PERIOD';

//Department
export const LOAD_DEPARTMENTS = 'LOAD_DEPARTMENTS';
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT';

//Lab
export const SET_LAB_TESTS = 'SET_LAB_TESTS';
export const ADD_LAB_TEST = 'ADD_LAB_TEST';
export const UPDATE_LAB_TEST = 'UPDATE_LAB_TEST';
export const DELETE_LAB_TEST = 'DELETE_LAB_TEST';
export const ADD_LAB_TEST_CATEGORY = 'ADD_LAB_TEST_CATEGORY';
export const GET_ALL_LAB_TEST_CATEGORIES = 'GET_ALL_LAB_TEST_CATEGORIES';
export const UPDATE_LAB_TEST_CATEGORY = 'UPDATE_LAB_TEST_CATEGORY';
export const DELETE_LAB_TEST_CATEGORY = 'DELETE_LAB_TEST_CATEGORY';

//Specialization
export const ADD_SPECIALIZATION = 'ADD_SPECIALIZATION';
export const GET_ALL_SPECIALIZATIONS = 'GET_ALL_SPECIALIZATIONS';
export const UPDATE_SPECIALIZATION = 'UPDATE_SPECIALIZATION';
export const DELETE_SPECIALIZATION = 'DELETE_SPECIALIZATION';

// utility
export const LOAD_BANKS = 'LOAD_BANKS';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';

// patient
export const READING_DONE = 'READING_DONE';

//Patient
export const PATIENT_IVF = 'PATIENT_IVF';

//Services
export const LOAD_SERVICES_CATEGORIES = 'LOAD_SERVICES_CATEGORIES';
export const LOAD_SERVICES = 'LOAD_SERVICES';
export const ADD_SERVICE = 'ADD_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const RESET_SERVICES = 'RESET_SERVICES';

//Vitals
export const UPDATE_VITALS = 'UPDATE_VITALS';
export const LOAD_VITALS = 'LOAD_VITALS';

//Transaction
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const LOAD_TRANSACTIONS = 'LOAD_TRANSACTIONS';
export const UPDATE_TRANSACTION = 'UPDATE_TRANSACTION';
export const ADD_TRANSACTION = 'ADD_TRANSACTION';

//Encounter
export const UPDATE_ENCOUNTER_DATA = 'UPDATE_ENCOUNTER_DATA';
export const RESET_ENCOUNTER_DATA = 'RESET_ENCOUNTER_DATA';
export const UPDATE_ASSESSMENT_DATA = 'UPDATE_ASSESSMENT_DATA';
export const UPDATE_SOAP_DATA = 'UPDATE_SOAP_DATA';

//Chat
export const SET_USERS = 'SET_USERS';
export const NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE';
export const RESET_CHAT_MESSAGE = 'RESET_CHAT_MESSAGE';
export const SET_UNREAD = 'SET_UNREAD';
export const SET_ACTIVE_USERS = 'SET_ACTIVE_USERS';
export const SET_RECENT_CHATS = 'SET_RECENT_CHATS';
export const SET_CHAT_MESSAGE_READ = 'SET_CHAT_MESSAGE_READ';
export const SET_CHAT_GROUPS = 'SET_CHAT_GROUPS';
export const ADD_CHAT_GROUP = 'ADD_CHAT_GROUP';
export const TOGGLE_CHAT_SIDEBAR = 'TOGGLE_CHAT_SIDEBAR';
export const SET_SELECTED_ROOM = 'SET_SELECTED_ROOM';
export const SET_CHAT_ID = 'SET_CHAT_ID';
export const SET_ROOM_ID = 'SET_ROOM_ID';
export const SET_SUPPORT_CHATS = 'SET_SUPPORT_CHATS';
export const SET_UNREAD_CHATS = 'SET_UNREAD_CHATS';
export const SET_IS_SUPPORT_CHAT = 'SET_IS_SUPPORT_CHAT';
export const SET_GROUPED_CHATS = 'SET_GROUPED_CHATS';
