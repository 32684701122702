import {
	SET_USERS,
	NEW_CHAT_MESSAGE,
	RESET_CHAT_MESSAGE,
	SET_UNREAD,
	SET_ACTIVE_USERS,
	SET_CHAT_MESSAGE_READ,
	SET_RECENT_CHATS,
	ADD_CHAT_GROUP,
	SET_CHAT_GROUPS,
	TOGGLE_CHAT_SIDEBAR,
	SET_ROOM_ID,
	SET_CHAT_ID,
	SET_SELECTED_ROOM,
	SET_SUPPORT_CHATS,
	SET_UNREAD_CHATS,
	SET_IS_SUPPORT_CHAT,
	SET_GROUPED_CHATS,
} from '../actions/types';

const initialState = {
	users: [],
	activeUsers: [],
	recentChats: [],
	unread: 0,
	isChatMessageRead: false,
	chatGroups: [],
	isChatSidebarOpen: false,
	selectedRoom: null,
	chatId: null,
	roomId: null,
	supportChats: [],
	unreadChats: [],
	isSupportChat: false,
	groupedChats: [],
};

const chat = (state = initialState, action) => {
	switch (action.type) {
		case SET_USERS:
			return { ...state, users: action.payload };
		case NEW_CHAT_MESSAGE:
			return { ...state, unread: state.unread + 1 };
		case RESET_CHAT_MESSAGE:
			return { ...state, unread: 0 };
		case SET_UNREAD:
			return { ...state, unread: action.payload };
		case SET_ACTIVE_USERS:
			return { ...state, activeUsers: action.payload };
		case SET_RECENT_CHATS:
			return { ...state, recentChats: action.payload };
		case SET_CHAT_MESSAGE_READ:
			return { ...state, isChatMessageRead: !state.isChatMessageRead };
		case SET_CHAT_GROUPS:
			return { ...state, chatGroups: action.payload };
		case ADD_CHAT_GROUP:
			return { ...state, chatGroups: [...state.chatGroups, action.payload] };
		case TOGGLE_CHAT_SIDEBAR:
			return { ...state, isChatSidebarOpen: !state.isChatSidebarOpen };
		case SET_SELECTED_ROOM:
			return { ...state, selectedRoom: action.payload };
		case SET_CHAT_ID:
			return { ...state, chatId: action.payload };
		case SET_ROOM_ID:
			return { ...state, roomId: action.payload };
		case SET_SUPPORT_CHATS:
			return { ...state, supportChats: action.payload };
		case SET_UNREAD_CHATS:
			return { ...state, unreadChats: action.payload };
		case SET_IS_SUPPORT_CHAT:
			return { ...state, isSupportChat: action.payload };
		case SET_GROUPED_CHATS:
			return { ...state, groupedChats: action.payload };
		default:
			return state;
	}
};

export default chat;
