import React, { useCallback, useEffect, useState } from 'react';
import AnaestheticForm from './AnaestheticForm';
import AnaestheticChart from './AnaestheticChart';
import TableLoading from '../TableLoading';
import { patientAPI } from '../../services/constants';
import { formatDate, request } from '../../services/utilities';
import AnaestheticRecord from './AnaestheticRecord';

const info = ['Blood Pressure', 'Respiration', 'spo', 'pulse'];

const Anaesthetic = ({ patient, labourId }) => {
	const [openForm, setOpenForm] = useState(false);
	const [loading, setLoading] = useState(true);
	const [chartData, setChartData] = useState([]);
	const [activePage, setActivePage] = useState('chart');

	const fetchVitals = useCallback(async () => {
		const url = `${patientAPI}/${patient.id}/vitals?labour_id=${labourId}`;
		const rs = await request(url, 'GET', true);

		let vitals = rs.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

		let data = [];

		const cloneVitals = [...vitals];
		cloneVitals
			.filter(c => info.includes(c.readingType))
			.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1))
			.forEach((item, index) => {
				const date = formatDate(item.createdAt, 'DD-MMM-YYYY h:mma');
				const res = { ...item.reading, name: date };
				data = [...data, res];
			});

		setChartData(data);

		setLoading(false);
	}, [labourId, patient.id]);

	const newEntry = () => {
		document.body.classList.add('modal-open');
		setOpenForm(true);
	};
	const closeEntry = () => {
		document.body.classList.remove('modal-open');
		setOpenForm(false);
	};

	useEffect(() => {
		if (loading) {
			fetchVitals();
		}
	}, []);

	return (
		<div className="col-sm-12">
			<div className="element-wrapper">
				<div className="element-actions flex-action">
					{activePage === 'chart' && (
						<a className="btn btn-sm btn-secondary text-white ml-3" onClick={() => newEntry()}>
							New Anaesthetic Record
						</a>
					)}
				</div>
				<ul className="nav nav-tabs upper mb-3">
					<li className="nav-item">
						<a
							className={`nav-link ${activePage === 'chart' ? 'active' : ''}`}
							onClick={() => {
								setActivePage('chart');
							}}
						>
							Anaesthetic Chart
						</a>
					</li>
					<li className="nav-item">
						<a
							aria-expanded="false"
							className={`nav-link ${activePage === 'record' ? 'active' : ''}`}
							onClick={() => {
								setActivePage('record');
							}}
						>
							Anaesthesia
						</a>
					</li>
				</ul>
				<div className="element-box p-3 m-0">
					{loading ? (
						<TableLoading />
					) : activePage === 'chart' && !openForm ? (
						<AnaestheticChart chartData={chartData} />
					) : (
						<AnaestheticRecord closeModal={closeEntry} patient={patient} labourId={labourId} />
					)}
				</div>
			</div>

			{openForm && <AnaestheticForm closeModal={closeEntry} patient={patient} enrollment_id={labourId} />}
		</div>
	);
};

export default Anaesthetic;
