import React from 'react';
import { Link } from 'react-router-dom';

const TherapyMenu = () => {
	return (
		<>
			<li>
				<Link to="/therapy">
					<div className="icon-w">
						<div className="icon-feather-home" />
					</div>
					<span>Dashboard</span>
				</Link>
			</li>
			<li>
				<Link to="/therapy/create">
					<div className="icon-w">
						<div className="icon-feather-folder-plus" />
					</div>
					<span>New Therapy</span>
				</Link>
			</li>
		</>
	);
};

export default TherapyMenu;
