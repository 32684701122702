import { io } from 'socket.io-client';

import { API_URI, TOKEN_COOKIE } from './constants';
import { messageService } from './message';
import SSRStorage from './storage';

let socket;
let chatSocket;

const profile = new SSRStorage().getLocalStorage(TOKEN_COOKIE);

export const initSocket = () => {
	console.log('socket initiatied');

	socket = io(`${API_URI}/socket`, { transports: ['websocket', 'polling'] });
	chatSocket = io(`${API_URI}/chat`, { transports: ['websocket', 'polling'] });
	if (socket) {
		socket.on('connect', () => {
			console.log(`connected to socket.io: ${socket.connected}: ${socket.id}`);
		});

		socket.io.on('reconnect', () => {
			console.log(`re-connected to socket.io`);
		});

		socket.on('disconnect', reason => {
			console.log(`user disconnected: ${reason}`);
		});
	}

	if (chatSocket) {
		chatSocket.on('connect', () => {
			console.log(`connected to chat socket.io: ${chatSocket.connected}: ${chatSocket.id}`);
			emitChat('login', {
				userId: profile?.details?.id,
			});
		});

		chatSocket.io.on('reconnect', () => {
			console.log(`re-connected chat to socket.io`);
		});

		chatSocket.on('disconnect', reason => {
			console.log(`user chat disconnected: ${reason}`);
		});
	}
};

export const subscribeIO = () => {
	if (socket) {
		// new appointment
		socket.on('new-appointment', data => {
			console.log(`'new-appointment' to socket.io`);
			messageService.sendMessage({ type: 'new-appointment', data });
		});

		// new transactions
		socket.on('paypoint-queue', data => {
			console.log(data);
		});

		// nursing vitals
		socket.on('nursing-queue', data => {
			console.log(`'nursing-queue' to socket.io`);
			messageService.sendMessage({ type: 'nursing-queue', data });
		});

		// consultation queue
		socket.on('consultation-queue', data => {
			console.log(`'consultation-queue' to socket.io`);
			messageService.sendMessage({ type: 'consultation-queue', data });
		});

		// update appointment
		socket.on('appointment-update', data => {
			console.log(`'appointment-update' to socket.io`);
			messageService.sendMessage({ type: 'appointment-update', data });
		});

		// lab result
		if (profile && (profile?.role?.slug === 'doctor' || profile?.role?.slug === 'medical-director' || profile?.role?.slug === 'hmo-doctor')) {
			socket.on(`lab-result-${profile.username}`, data => {
				console.log('lab result', data);
				messageService.sendMessage({ type: 'lab-result', data });
			});
		}
	}

	if (chatSocket) {
		const profileId = profile?.details?.id;

		chatSocket.on(`${profileId}`, data => {
			console.log(`'new-chat-message' to socket.io`);
			messageService.sendMessage({ type: 'new-chat-message', data });
		});

		chatSocket.on('active_users', data => {
			console.log(`'active_users' to socket.io`);
			messageService.sendMessage({ type: 'active_users', data });
		});

		if (profile?.role?.slug === 'it-admin') {
			chatSocket.on('receive_support', data => {
				console.log(`'new-support-message' to socket.io`);
				messageService.sendMessage({ type: 'new-support-message', data });
			});
		} else {
			chatSocket.on(`support-${profileId}`, data => {
				console.log(`'new-support-reply-message' to socket.io`);
				messageService.sendMessage({ type: 'new-support-reply-message', data });
			});
		}

		chatSocket.on(`${profileId}-read`, data => {
			console.log(`'message-read' to socket.io`);
			messageService.sendMessage({ type: 'message-read', data });
		});
	}
};

export const emit = (event, data) => {
	if (socket) {
		socket.emit(event, data);
	}
};

export const emitChat = (event, data) => {
	if (chatSocket) {
		chatSocket.emit(event, data);
	}
};

export const disconnectSocket = () => {
	if (socket) {
		socket.disconnect();
	}
};

export const disconnectChatSocket = () => {
	if (chatSocket) {
		chatSocket.disconnect();
	}
};

export const getSocket = () => socket;

export const getChatSocket = () => chatSocket;
