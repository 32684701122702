import React, { useState, useCallback, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { patientname } from '../../services/utilities';
import { request } from '../../services/utilities';

const AnaestheticRecord = ({ patient, labourId }) => {
	const [record, setAnaestheticRecord] = useState(null);
	const [loaded, setLoaded] = useState(false);

	const fetchAnaestheticRecord = useCallback(async () => {
		try {
			const url = `labour-management/anaesthetic?enrollment_id=${labourId}`;
			const rs = await request(url, 'GET', true);
			if (rs.success) {
				console.log('anaestheticRecord:- ', rs.data);
				setAnaestheticRecord(rs.data);
			}
			setLoaded(true);
		} catch (error) {
			console.log('anaestheticRecord error:- ', error);
		}
	}, [labourId]);

	useEffect(() => {
		if (!loaded) {
			fetchAnaestheticRecord();
		}
	}, []);

	return record ? (
		<div className="container-fluid">
			<div className="container mt-3">
				<h6>Anaesthetic Data</h6>
				<div className="row">
					<div className="col-sm-3">
						<div className="form-group">
							<label>Patient</label>
							<input type="text" className="form-control" value={patientname(patient)} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Surgeons</label>
							<input type="text" className="form-control" value={record?.surgeons || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Anaesthesist</label>
							<input type="text" className="form-control" value={record?.anaesthesist || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Scheduled Surgery</label>
							<input type="text" className="form-control" value={record?.surgery || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Elective Emergency</label>
							<input type="text" className="form-control" value={record?.emergency || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Preoprative Findings</label>
							<input type="text" className="form-control" value={record?.findings || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-6">
						<div className="form-group">
							<label>Recreational Drugs</label>
							<input type="text" className="form-control" value={record?.recreational_drugs || ''} readOnly />
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-3">
				<div className="row">
					<div className="col-sm-3">
						<div className="form-group">
							<label>Premedication</label>
							<input type="text" className="form-control" value={record?.premedication || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Pre-op investigations</label>
							<input type="text" className="form-control" value={record?.pre_op_investigations || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Blood Sugar</label>
							<input type="text" className="form-control" value={record?.blood_sugar || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Viral Screening</label>
							<input type="text" className="form-control" value={record?.viral_screening || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Analgesia</label>
							<input type="text" className="form-control" value={record?.analgesia || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Anaesthetic Technique </label>
							<input type="text" className="form-control" value={record?.anaesthetic_technique || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Induction</label>
							<input type="text" className="form-control" value={record?.induction || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Intubation</label>
							<input type="text" className="form-control" value={record?.intubation || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-6">
						<div className="form-group">
							<label>Maintenance</label>
							<input type="text" className="form-control" value={record?.maintenance || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-6">
						<div className="form-group">
							<label>Inhalation/Gases</label>
							<input className="form-control" rows="3" value={record?.inhalation || ''} readOnly></input>
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-3">
				<div className="row">
					<div className="col-sm-3">
						<div className="form-group">
							<label>Other Drugs</label>
							<input type="text" className="form-control" value={record?.other_drugs || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Regional</label>
							<input type="text" className="form-control" value={record?.regional || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Remarks</label>
							<input type="text" className="form-control" value={record?.remarks || ''} readOnly />
						</div>
					</div>
					{record?.remarks === 'BLD' && (
						<>
							<div className="col-sm-3">
								<div className="form-group">
									<label>LOSS</label>
									<input type="text" className="form-control" value={record?.loss || ''} readOnly />
								</div>
							</div>
							<div className="col-sm-3">
								<div className="form-group">
									<label>Colour</label>
									<input type="text" className="form-control" value={record?.colour || ''} readOnly />
								</div>
							</div>
						</>
					)}
					<div className="col-sm-3">
						<div className="form-group">
							<label>Spontaneous Respiration</label>
							<input type="text" className="form-control" value={record?.is_spontaneous_respiration ? 'YES' : 'NO'} readOnly />
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-3">
				<h6>Ventilator Settings</h6>
				<div className="row">
					<div className="col-sm-3">
						<div className="form-group">
							<label>MV</label>
							<input type="text" className="form-control" value={record?.mv || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>RR</label>
							<input type="text" className="form-control" value={record?.rr || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>I:E</label>
							<input type="text" className="form-control" value={record?.ie || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>PRESS</label>
							<input type="text" className="form-control" value={record?.press || ''} readOnly />
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-3">
				<h6>Fluid</h6>
				<div className="row">
					<div className="col-sm-3">
						<div className="form-group">
							<label>N/S</label>
							<input type="text" className="form-control" value={record?.n_s || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>5% DEXTROSE</label>
							<input type="text" className="form-control" value={record?.dextrose || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>4.3%</label>
							<input type="text" className="form-control" value={record?.four_three_percent || ''} readOnly />
						</div>
					</div>
				</div>
			</div>

			<div className="container mt-3">
				<h6>Other Drugs</h6>
				<div className="row">
					<div className="col-sm-3">
						<div className="form-group">
							<label>Reversal</label>
							<input type="text" className="form-control" value={record?.reversal || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Post-Op Condition</label>
							<input type="text" className="form-control" value={record?.post_op_condition || ''} readOnly />
						</div>
					</div>
					<div className="col-sm-3">
						<div className="form-group">
							<label>Transfer To</label>
							<input type="text" className="form-control" value={record?.transfer_to || ''} readOnly />
						</div>
					</div>
				</div>
			</div>
		</div>
	) : (
		<div>No Data</div>
	);
};

export default AnaestheticRecord;
