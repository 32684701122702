import React, { useState } from 'react';
import ModalHeader from '../ModalHeader';
import SunEditor from 'suneditor-react';
import { dischargeTemplate } from '../../services/constants';

const ModalDischargePatient = ({ admissionId, nicuId, startDischarge, closeModal, type }) => {
	const [note, setNote] = useState(dischargeTemplate || '');

	const id = type === 'nicu' ? nicuId : admissionId;

	return (
		<div className="onboarding-modal modal fade animated show" role="dialog" style={{ display: 'block' }}>
			<div className="modal-dialog modal-centered" style={{ maxWidth: '720px' }}>
				<div className="modal-content text-center">
					<ModalHeader closeModal={closeModal} title="Doctor's Discharge Note" />
					<div className="onboarding-content with-gradient">
						<div className="element-box p-2">
							<div className="row" style={{ textAlign: 'left' }}>
								<div className="col-sm-12">
									<div className="form-group">
										<SunEditor
											width="100%"
											placeholder="Please type here..."
											setContents={note}
											name="complaint_data"
											autoFocus={true}
											enableToolbar={true}
											setOptions={{
												height: 300,
												buttonList: [
													[
														'bold',
														'underline',
														'italic',
														'strike',
														'subscript',
														'superscript',
														'list',
														'align',
														'font',
														'fontSize',
														'image',
														'codeView',
													],
												],
											}}
											onChange={e => {
												setNote(String(e));
											}}
										/>
									</div>
								</div>
								<div className="col-sm-12 mt-2">
									<div className="form-inline" style={{ justifyContent: 'center' }}>
										<button onClick={() => startDischarge(id, { note }, type)} className="btn btn-primary">
											Discharge Patient
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalDischargePatient;
