import React, { useState } from 'react';
import DatePicker from 'antd/lib/date-picker';
import { Field } from 'react-final-form';
import moment from 'moment';
import FormWizard from '../FormWizard';
import { Compulsory, ErrorBlock, request } from '../../services/utilities';
import { notifyError, notifySuccess } from '../../services/notify';

import { days } from '../../services/constants';

const ChartForm = ({ closeModal, patient_id, ivfId, fetchChartData }) => {
	const [date, setDate] = useState('');
	const [lmp, setLmp] = useState('');

	const [day, setDay] = useState('');
	const [endo, setEndos] = useState('');

	const [leftValue, setLeftValue] = useState([]);
	const [rightValue, setRightValue] = useState([]);

	// for left or right ovaries
	const [measurement, setMeasurement] = useState('');
	const [totalFollicles, setTotalfolicles] = useState('');

	const initialValues = {
		type: '',
		antagonist: '',
		pill: '',
		suprecur: '',
		fsh: '',
		hmg: '',
	};

	const pushToLeft = () => {
		const newLeftOvary = [
			...leftValue,
			{
				totalFollicles: parseInt(totalFollicles, 10),
				measurement: parseInt(measurement, 10),
			},
		];

		setLeftValue(newLeftOvary);
		setTotalfolicles('');
		setMeasurement('');
	};

	// Push To Right Ovary
	const pushToRight = () => {
		const newRightOvary = [
			...rightValue,
			{
				totalFollicles: parseInt(totalFollicles, 10),
				measurement: parseInt(measurement, 10),
			},
		];

		setRightValue(newRightOvary);
		setTotalfolicles('');
		setMeasurement('');
	};

	const removeFromLeft = index => {
		const newLeftValue = leftValue.filter((_val, i) => index !== i);

		setLeftValue(newLeftValue);
	};
	const removeFromRight = index => {
		const newRightValue = rightValue.filter((_val, i) => index !== i);

		setRightValue(newRightValue);
	};

	const onSubmit = async values => {
		const data = {
			type: values.type,
			date: moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
			imp: moment(lmp, 'DD-MM-YYYY').format('YYYY-MM-DD'),
			antagonist: values.antagonist,
			piii: values.pill,
			suprecur: values.suprecur,
			fsh: values.fsh,
			hmg: values.hmg,
			dose: values.dose,
			stimulation_data: [
				{
					day,
					endo: endo,
					left: leftValue,
					right: rightValue,
				},
			],
			patient_id: patient_id,
			enrollment_id: ivfId,
		};
		console.log('onsubmit', data);

		try {
			const url = `ivf/stimulation`;
			const rs = await request(url, 'POST', true, data);
			console.log(rs);

			if (rs.success) {
				notifySuccess('Save Successful!');
				closeModal();
				fetchChartData();
			} else {
				notifyError('Failed to Save!');
				closeModal();
			}
		} catch (error) {
			console.log(error);
			notifyError('Failed to Save!');
			closeModal();
		}
	};

	return (
		<div className="onboarding-modal modal fade animated show" role="dialog" style={{ display: 'block' }}>
			<div className="modal-dialog modal-content modal-centered modal-scroll" style={{ maxWidth: '1024px' }}>
				<div className="modal-content text-center">
					<button aria-label="Close" className="close" type="button" onClick={closeModal}>
						<span className="os-icon os-icon-close"></span>
					</button>
					<div className="onboarding-content with-gradient">
						<h4 className="onboarding-title">Stimulation Chart</h4>
						{/* form starts */}
						<div className="form-block element-box">
							<FormWizard
								initialValues={Object.fromEntries(Object.entries(initialValues).filter(([_, v]) => v !== ''))}
								onSubmit={onSubmit}
								btnClasses="modal-footer buttons-on-right"
							>
								<FormWizard.Page
									validate={values => {
										const errors = {};

										if (!date) {
											errors.date = 'select date';
										}

										if (!lmp) {
											errors.lmp = 'enter lmp';
										}

										return errors;
									}}
								>
									<div className="row">
										<div className="col-sm-3">
											<div className="form-group">
												<label>Type</label>

												<Field name="type" className="form-control" component="input" type="text" />
												<ErrorBlock name="type" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>Date</label>
												<Compulsory />
												<DatePicker className="form-control" onChange={e => setDate(e)} />
												<ErrorBlock name="date" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>LMP</label>
												<Compulsory />
												<DatePicker className="form-control" onChange={e => setLmp(e)} />
												<ErrorBlock name="lmp" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>Antagonist</label>

												<Field name="antagonist" className="form-control" component="input" type="text" />
												<ErrorBlock name="antagonist" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>Pill</label>
												<Field name="pill" className="form-control" component="input" type="text" />
												<ErrorBlock name="pill" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>Suprecur</label>

												<Field name="suprecur" className="form-control" component="input" type="text" />
												<ErrorBlock name="suprecur" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>FSH</label>

												<Field name="fsh" className="form-control" component="input" type="text" />
												<ErrorBlock name="fsh" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>HMG</label>
												<Field name="hmg" className="form-control" component="input" type="text" />
												<ErrorBlock name="hmg" />
											</div>
										</div>
										<div className="col-sm-3">
											<div className="form-group">
												<label>DOSE</label>
												<Field name="dose" className="form-control" component="input" type="text" />
												<ErrorBlock name="dose" />
											</div>
										</div>
									</div>

									<hr></hr>

									{/* STIMULATION TABLE */}

									<div className="row align-items-center">
										<div className="col-sm-3">
											<div className="form-group">
												<label>DAY</label>
												<select name="day" id="day" value={day} className="form-control" onChange={e => setDay(e.target.value)}>
													<option value="">select day</option>
													{days.map(day => (
														<option value={day.id}>{day.name}</option>
													))}
												</select>
											</div>
										</div>

										<div class="col-sm-3">
											<div className="form-group">
												<label>ENDO</label>
												<div class="input-group">
													<input
														class="form-control"
														placeholder="endometrial thickness"
														value={endo}
														type="text"
														onChange={e => setEndos(e.target.value)}
													/>
													<div class="input-group-prepend">
														<div class="input-group-text">mm</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									{/* NEW ROW */}
									<div className="row">
										<div className="col-sm-2">
											<div className="form-group">
												<label>No. of Follicles</label>
												<input className="form-control" type="text" value={totalFollicles} onChange={e => setTotalfolicles(e.target.value)} />
												{/* <ErrorBlock name="right" /> */}
											</div>
										</div>
										<div className="col-sm-2">
											<div className="form-group">
												<label>Measurement (mm)</label>
												<input className="form-control" type="text" value={measurement} onChange={e => setMeasurement(e.target.value)} />
												{/* <ErrorBlock name="right" /> */}
											</div>
										</div>
										<div className="col-sm-3 mt-4">
											<div className="form-group">
												<a className="btn btn-sm btn-success btn-upper text-white" onClick={pushToLeft}>
													<i className="os-icon os-icon-plus" />
													<span>ADD TO LEFT</span>
												</a>
											</div>
										</div>
										<div className="col-sm-3 mt-4">
											<div className="form-group">
												<a className="btn btn-sm btn-success btn-upper text-white" onClick={pushToRight}>
													<i className="os-icon os-icon-plus" />
													<span>ADD TO RIGHT</span>
												</a>
											</div>
										</div>

										{/* End of row for Left and Right data */}
									</div>

									{/* START TABLE */}
									<div className="row">
										<div className="col-sm-6">
											<table className="table table-striped table-bordered">
												<thead>
													<tr>
														<th>Number of Folicles</th>
														<th>Measurement (mm)</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{leftValue.map((data, i) => (
														<tr key={i}>
															<td>{data?.totalFollicles}</td>
															<td>{data?.measurement}</td>
															<td className="flex flex-row">
																<a className="btn btn-sm btn-danger btn-upper text-white m-0" onClick={() => removeFromLeft(i)}>
																	<i className="os-icon os-icon-trash" />
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
										<div className="col-sm-6">
											<table className="table table-striped table-bordered">
												<thead>
													<tr>
														<th>Number of Folicles</th>
														<th>Measurement (mm)</th>
														<th></th>
													</tr>
												</thead>
												<tbody>
													{rightValue.map((data, i) => (
														<tr key={i}>
															<td>{data?.totalFollicles}</td>
															<td>{data?.measurement}</td>
															<td className="flex flex-row">
																<a className="btn btn-sm btn-danger btn-upper text-white m-0" onClick={() => removeFromRight(i)}>
																	<i className="os-icon os-icon-trash" />
																</a>
															</td>
														</tr>
													))}
												</tbody>
											</table>
										</div>
									</div>

									{/* TABLE ENDS */}
								</FormWizard.Page>
							</FormWizard>
						</div>

						{/* Extra Form Popup */}

						{/* End Of Extra Form Popup */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default ChartForm;
