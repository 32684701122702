import useSound from 'use-sound';
import chat from '../assets/audio/chat.mp3';
import support from '../assets/audio/support.wav';
import { useEffect, useRef } from 'react';

const SoundeComp = ({ playSound, chatType }) => {
	const audioRef = useRef(null);
	const [playChat] = useSound(chat);
	const [playSupport] = useSound(support);

	useEffect(() => {
		if (playSound > 0) {
			if (audioRef.current) {
				audioRef.current.click();
			}
		}
	}, [audioRef, playSound, chatType]);

	return (
		<>
			<button
				ref={audioRef}
				onClick={chatType === 'support' ? playSupport : playChat}
				style={{ display: 'none' }}
			>
				Boop!
			</button>
		</>
	);
};

export default SoundeComp;
