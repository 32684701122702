import React, { useState } from 'react';

const RenderLoginTextInput = ({ input, label, type, id, placeholder, icon, meta: { touched, error } }) => {
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<div className={`form-group ${touched && (error ? 'has-error has-danger' : '')}`}>
			<label htmlFor={id}>{label}</label>
			<div className="input-group">
				<input {...input} type={showPassword ? 'text' : type} placeholder={placeholder} className="form-control" />
				<div className="input-group-append">
					<span
						className={`input-group-text ${showPassword || type !== 'password' ? '' : 'bg-secondary text-white'}`}
						onClick={togglePasswordVisibility}
						style={{ cursor: 'pointer' }}
					>
						<i className={`pre-icon os-icon ${icon}`} />
					</span>
				</div>
			</div>
		</div>
	);
};

export default RenderLoginTextInput;
