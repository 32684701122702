import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AnaestheticChart = ({ chartData }) => {
	const options = {
		chart: {
			type: 'area',
			stacked: false,
			toolbar: false,
		},
		stroke: {
			curve: 'smooth',
		},
		series: [
			{
				name: 'Blood Pressure Systolic',
				data: chartData
					?.map(d => d.blood_pressure)
					.filter(item => item !== undefined)
					.map(item => item.split('/'))
					.map(item => item[0]),
			},
			{
				name: 'Blood Pressure Diastolic',
				data: chartData
					?.map(d => d.blood_pressure)
					.filter(item => item !== undefined)
					.map(item => item.split('/'))
					.map(item => item[1]),
			},
			{
				name: 'Pulse',
				data: chartData?.map(d => d.pulse).filter(item => item !== undefined),
			},
			{
				name: 'Spo2',
				data: chartData?.map(d => d.spo).filter(item => item !== undefined),
			},
			{
				name: 'Respiratory rate',
				data: chartData?.map(d => d.respiration).filter(item => item !== undefined),
			},
		],
		// xaxis: {
		// 	categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
		// },
	};

	return (
		<div>
			<div className="el-chart-w">
				<ReactApexChart options={options} series={options.series} height="339" type="area" className="apex-charts" />
			</div>
		</div>
	);
};

export default AnaestheticChart;
