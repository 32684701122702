import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const TeamMenu = () => {
	const profile = useSelector(state => state.user.profile);
	const departments = useSelector(state => state.department);

	const checkHOD = (staff, departments) => {
		for (const department of departments) {
			if (staff.id === department.hod_id) {
				return department;
			}
		}
		return null;
	};

	return (
		<>
			{/* <li>
				<Link to="/schedule/roster">
					<div className="icon-w">
						<div className="os-icon os-icon-newspaper" />
					</div>
					<span>Schedule</span>
				</Link>
			</li> */}
			<li>
				<Link to="/schedule/roster">
					<div className="icon-w">
						<div className="os-icon os-icon-newspaper" />
					</div>
					<span>Roster</span>
				</Link>
			</li>
			{/* {checkHOD(profile.details, departments)?.units.length ? ( */}
			<li>
				<Link to="/schedule/units">
					<div className="icon-w">
						<div className="os-icon os-icon-plus-square" />
					</div>
					<span>Units</span>
				</Link>
			</li>
			{/* ) : (
				<></>
			)} */}
		</>
	);
};

export default TeamMenu;
