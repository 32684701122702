/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { confirmAction, formatDate, request } from '../../services/utilities';
import { startBlock, stopBlock } from '../../actions/redux-block';
import { notifyError, notifySuccess } from '../../services/notify';
import { criticalList } from '../../services/constants';
import { messageService } from '../../services/message';
import ModalHeader from '../ModalHeader';
import startCase from 'lodash.startcase';

const ViewAlerts = ({ closeModal, patient }) => {
	const [loading, setLoading] = useState(true);
	const [alerts, setAlerts] = useState([]);
	const [criticalAlerts, setCriticalAlerts] = useState([]);
	const [allergies, setAllergies] = useState([]);
	const [bpAlert, setBpAlert] = useState(null);

	const dispatch = useDispatch();

	const user = useSelector(state => state.user.profile);

	const fetchAlerts = useCallback(async () => {
		try {
			dispatch(startBlock());
			const url = `patient/${patient.id}/alerts?category=normal`;
			const rs = await request(url, 'GET', true);
			setAlerts(rs);

			const uri = `patient/${patient.id}/alerts?category=critical`;
			const res = await request(uri, 'GET', true);
			setCriticalAlerts(res);

			const allergyUrl = `patient/${patient.id}/alerts?category=allergy`;
			const allergyRes = await request(allergyUrl, 'GET', true);
			setAllergies(allergyRes);

			const bpUrl = `patient/${patient.id}/vital-alerts?reading_type=blood pressure&abnormal=1`;
			const bpRes = await request(bpUrl, 'GET', true);
			if (bpRes.success) {
				const data = bpRes.data;
				setBpAlert(data);
			}

			dispatch(stopBlock());
		} catch (error) {
			console.log(error);
			dispatch(stopBlock());
			notifyError('error fetching alerts');
		}
	}, [dispatch, patient]);

	useEffect(() => {
		if (loading) {
			fetchAlerts();
			setLoading(false);
		}
	}, [fetchAlerts, loading]);

	const clear = async id => {
		confirmAction(doResolve, id, 'Do you want to clear this alert?', 'Are you sure?');
	};

	const clearAllergy = async id => {
		confirmAction(doResolveAllergy, id, 'Do you want to clear this allergy alert?', 'Are you sure?');
	};

	const clearBp = async id => {
		confirmAction(doResolveBp, id, 'Do you want to clear this BP alert?', 'Are you sure?');
	};

	const doResolve = async id => {
		try {
			dispatch(startBlock());
			const url = `patient/${id}/close-alert`;
			const rs = await request(url, 'PATCH', true);
			setAlerts([...alerts.filter(a => a.id !== rs.id)]);
			notifySuccess('alert removed');
			messageService.sendMessage({ type: 'refresh-alerts' });
			dispatch(stopBlock());
		} catch (e) {
			dispatch(stopBlock());
			notifyError(e.message || 'could not clear alert');
		}
	};

	const doResolveAllergy = async id => {
		try {
			dispatch(startBlock());
			const url = `patient/${id}/close-alert?type=allergy`;
			const rs = await request(url, 'PATCH', true);
			setAllergies([...allergies.filter(a => a.id !== rs.id)]);
			notifySuccess('alert removed');
			messageService.sendMessage({ type: 'refresh-alerts' });
			dispatch(stopBlock());
		} catch (e) {
			dispatch(stopBlock());
			notifyError(e.message || 'could not clear alert');
		}
	};

	const doResolveBp = async id => {
		try {
			dispatch(startBlock());
			const url = `patient/${id}/close-alert?type=blood-pressure`;
			const rs = await request(url, 'PATCH', true);
			setBpAlert(null);
			notifySuccess('BP alert removed');
			messageService.sendMessage({ type: 'refresh-alerts' });
			dispatch(stopBlock());
		} catch (e) {
			dispatch(stopBlock());
			notifyError(e.message || 'could not clear alert');
		}
	};

	return (
		<div className="onboarding-modal modal fade animated show" role="dialog" style={{ display: 'block' }}>
			<div className="modal-dialog modal-centered">
				<div className="modal-content modal-scroll text-center">
					<ModalHeader closeModal={() => closeModal()} title="Alerts" />
					<div className="onboarding-content with-gradient">
						{bpAlert && (
							<div className="table-responsive">
								<div className="alert-box">
									<div key={bpAlert.id} className="d-flex space-between align-items-center alert alert-warning mb-2">
										<a className="icon text-danger">
											<i className="os-icon os-icon-alert-triangle" />
										</a>
										<div className="text-left">
											<h5 className="text-danger">{startCase(bpAlert.status)} Blood Pressure</h5>
											<p className="m-0">
												<span>{`${formatDate(bpAlert.createdAt, 'DD-MMM-YYYY h:mm a')} ${
													bpAlert.createdBy === 'admin' ? '' : `by ${bpAlert.createdBy}`
												}`}</span>
											</p>
											<p>
												Last Reading: &nbsp;&nbsp; <strong>{bpAlert.reading?.blood_pressure}</strong>
											</p>
										</div>
										<div>
											{(user.role.slug === 'doctor' || user.role.slug === 'it-admin' || user.role.slug === 'medical-director') && (
												<a
													onClick={() => {
														clearBp(bpAlert.id);
													}}
												>
													<i className="os-icon os-icon-close" />
												</a>
											)}
										</div>
									</div>
								</div>
							</div>
						)}
						<div className="table-responsive">
							<div className="alert-box">
								{allergies.map((item, i) => {
									return (
										<div key={i} className="d-flex space-between align-items-center alert alert-warning mb-2">
											<a className="icon text-danger">
												<i className="os-icon os-icon-alert-triangle" />
											</a>
											<div className="text-left">
												<h5 className={item.severity?.toLowerCase() === 'severe' ? 'text-danger' : ''}>
													{startCase(item.severity)} {startCase(item.category)} {startCase(item.type)}
												</h5>
												<p className="m-0">
													<small>{`${formatDate(item.createdAt, 'DD-MMM-YYYY h:mm a')} ${
														item.createdBy === 'admin' ? '' : `by ${item.createdBy}`
													}`}</small>
												</p>
												<p>
													<span>{item?.category === 'Drug' && `Drug: ${item?.drugGeneric?.name}`}</span> | {startCase(item?.allergy)} |{' '}
													{startCase(item?.reaction)}
												</p>
											</div>
											<div>
												{(user.role.slug === 'doctor' || user.role.slug === 'it-admin' || user.role.slug === 'medical-director') && (
													<a
														onClick={() => {
															clearAllergy(item.id);
														}}
													>
														<i className="os-icon os-icon-close" />
													</a>
												)}
											</div>
										</div>
									);
								})}
							</div>
						</div>
						<div className="mb-4">
							<div className="row">
								{criticalAlerts.map((item, i) => {
									const criticalItem = criticalList.find(i => i.value === item.type);
									const isTwo = criticalAlerts.length % 2;
									const br = i === criticalAlerts.length - 1 ? '' : 'b-r';
									return (
										item && (
											<div
												key={i}
												className={`b-b ${br} ${
													criticalAlerts.length === 0 ? 'col-12' : isTwo === 0 && criticalAlerts.length <= 4 ? 'col-6' : 'col-4'
												}`}
											>
												<div className="el-tablo centered padded-v highlight bigger">
													<div className="label text-danger">{criticalItem.label}</div>
													<div className="icon">
														<img src={require(`../../assets/svg-icons/${item.type}.svg`)} alt="" />
													</div>
												</div>
											</div>
										)
									);
								})}
							</div>
						</div>
						<div className="table-responsive">
							<div className="alert-box">
								{alerts.map((item, i) => {
									return (
										<div key={i} className="d-flex space-between align-items-center alert alert-warning mb-2">
											<a className="icon">
												<i className="os-icon os-icon-alert-triangle" />
											</a>
											<div className="text-left">
												<h5>{item.type}</h5>
												<p className="m-0">
													<small>{`${formatDate(item.createdAt, 'DD-MMM-YYYY h:mm a')} ${
														item.createdBy === 'admin' ? '' : `by ${item.createdBy}`
													}`}</small>
												</p>
												<p>{item.message}</p>
											</div>
											<div>
												{(user.role.slug === 'doctor' || user.role.slug === 'it-admin' || user.role.slug === 'medical-director') && (
													<a onClick={() => clear(item.id)}>
														<i className="os-icon os-icon-close" />
													</a>
												)}
											</div>
										</div>
									);
								})}
							</div>
							{alerts.length === 0 && !bpAlert && !allergies.length && !loading && (
								<table className="table table-striped">
									<tbody>
										<tr>
											<td className="text-center">No Alerts!</td>
										</tr>
									</tbody>
								</table>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewAlerts;
