import ReactApexChart from 'react-apexcharts';

const EndoChart = ({ mydata }) => {
	const options = {
		chart: {
			toolbar: {
				show: true,
				offsetX: 0,
				offsetY: 0,
				tools: {
					download: true,
					selection: true,
					zoom: false,
					zoomin: false,
					zoomout: false,
					pan: false,
					reset: false,
				},
			},
		},
		stroke: {
			curve: 'smooth',
		},
		markers: {
			size: 6,
		},
		xaxis: {
			categories: mydata?.map(day => day.day),
		},
		yaxis: [
			{
				title: {
					text: 'Endos',
				},
				lines: {
					show: true,
				},
				axisBorder: {
					show: true,
					color: '#FF1654',
				},
			},
		],
	};

	const series = [
		{
			name: 'endo',
			type: 'line',
			data: mydata?.map(day => day.endo),
		},
	];
	return (
		<div className="el-chart-w">
			<ReactApexChart options={options} series={series} height="339" type="line" className="apex-charts" />
		</div>
	);
};

export default EndoChart;
