import React, { useEffect, useState } from 'react';
import { request } from '../../../services/utilities';
import { notifyError } from '../../../services/notify';
import TableLoading from '../../TableLoading';
import { days } from '../../../services/constants';
import { isEmpty } from 'lodash';

const RightOvaryChart = ({ stimulationId }) => {
	const [loaded, setLoaded] = useState(false);
	const [rightOvary, setRightOvary] = useState({});

	const fetchChartData = async () => {
		const url = `ivf/stimulation/data?stimulation_id=${stimulationId}`;
		try {
			const rs = await request(url, 'GET', true);
			if (rs.success) {
				setRightOvary(rs?.right);
				setLoaded(true);
			} else {
				setLoaded(true);
				console.log(rs, 'result');
			}
		} catch (error) {
			console.log(error);
			setLoaded(true);
			notifyError('error fetching chart data');
		}
	};

	useEffect(() => {
		if (!loaded) {
			fetchChartData();
		}
	}, []);

	return (
		<div className="col-md-12">
			<div className="element-wrapper">
				<div className="element-box p-3 m-0">
					<div className="table-responsive" style={{ overflowX: 'scroll' }}>
						<table className="table table-bordered table-md table-v2 table-striped">
							<thead>
								<tr>
									{days.map(day => {
										if (rightOvary[day.name]) {
											return <th key={day.id}>{`day ${day.name}`}</th>;
										}
									})}
								</tr>
							</thead>
							{!loaded ? (
								<TableLoading />
							) : (
								<tbody>
									{isEmpty(rightOvary) ? (
										<tr className="text-center">
											<td colSpan="8">No Record</td>
										</tr>
									) : (
										<tr>
											{days.map((day, i) => {
												if (rightOvary[day.name]) {
													return (
														<td style={{ border: '1px white solid' }}>
															{rightOvary[day?.id]
																.sort((a, b) => b.measurement - a.measurement)
																.map((item, j) => {
																	return (
																		<tr>
																			<td style={{ backgroundColor: 'pink' }}>{item.totalFollicles}</td>
																			<td style={{ backgroundColor: 'lightblue' }}>{item.measurement}</td>
																		</tr>
																	);
																})}
														</td>
													);
												}
											})}
										</tr>
									)}
								</tbody>
							)}
						</table>
					</div>

					<div className="d-flex justify-content-center">
						<div className="px-4 py-4">
							<span className="px-4 mx-2 rounded" style={{ backgroundColor: 'pink' }}></span>
							<span className="fw-bold">Total Follicles</span>
						</div>
						<div className="px-4 py-4">
							<span className="px-4 mx-2 rounded" style={{ backgroundColor: 'lightblue' }}></span>
							<span className="fw-bold">Measurement</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RightOvaryChart;
