import React from 'react';
import { Form, Field } from 'react-final-form';
import Select from 'react-select';
import { patientname, request } from '../../services/utilities';
import { notifyError, notifySuccess } from '../../services/notify';

const Error = ({ name }) => (
	<Field
		name={name}
		subscription={{ touched: true, error: true }}
		render={({ meta: { touched, error } }) => (touched && error ? <small className="text-danger">{error}</small> : null)}
	/>
);

const ReactSelectAdapter = ({ input, ...rest }) => (
	<Select getOptionValue={option => option.id} getOptionLabel={option => option.name} {...input} {...rest} searchable />
);

const Findings = [
	{
		id: 'Hypertensive',
		name: 'Hypertensive',
	},
	{
		id: 'Diabetic',
		name: 'Diabetic',
	},
	{
		id: 'Asthmatic',
		name: 'Asthmatic',
	},
	{
		id: 'Alcohol',
		name: 'Alcohol',
	},
	{
		id: 'Tobbaco',
		name: 'Tobbaco',
	},
	{
		id: 'Allergies',
		name: 'Allergies',
	},
];

const RecreationalDrugs = [
	{
		id: 'PREVIOUS ANAESTHESIA (UNEVENTFUL)',
		name: 'PREVIOUS ANAESTHESIA (UNEVENTFUL)',
	},
	{
		id: 'PREVIOUS ANAESTHESIA (EVENTFUL)',
		name: 'PREVIOUS ANAESTHESIA (EVENTFUL)',
	},
];

const AnaestheticTechnique = [
	{
		id: '1',
		name: '(GA)(REGIONAL)(NERVE bLOCK)',
	},

	{
		id: '2',
		name: '(LA)(MONITORED ANAESTHSIA CARE)',
	},
	{
		id: '2',
		name: '(GA)(ETT)(FACE MASK)',
	},
	{
		id: '4',
		name: '(LMA)',
	},
];

const RemarkOptions = [
	{
		id: 'MONITORS_NIBP',
		name: 'MONITORS NIBP',
	},
	{
		id: 'ECG',
		name: 'ECG',
	},
	{
		id: 'PR',
		name: 'PR',
	},
	{
		id: 'SPO2',
		name: 'SPO 2',
	},
	{
		id: 'BLD',
		name: 'BLD',
	},
	{
		id: 'URINE_OUTPUT',
		name: 'URINE OUTPUT',
	},
];

const YesOrNo = [
	{
		id: 'NO',
		name: 'NO',
	},
	{
		id: 'YES',
		name: 'YES',
	},
];

const ReversalOptions = [
	{
		id: 'Neostigime',
		name: 'Neostigime',
	},
	{
		id: 'Atrophine',
		name: 'Atrophine',
	},
	{
		id: 'Glycop',
		name: 'Glycop',
	},
];
const PostOp_ConditionOptions = [
	{
		id: 'Satisfactory',
		name: 'Satisfactory',
	},
	{
		id: 'Fair',
		name: 'Fair',
	},
	{
		id: 'Unstable',
		name: 'Unstable',
	},
];

const AnaestheticForm = ({ closeModal, patient, enrollment_id }) => {
	const onSubmit = async values => {
		console.log('onSubmit', values);
		const {
			spontaneous,
			preoperative_findings,
			recreational_drugs,
			anaesthetic_technique,
			remarks,
			reversal,
			post_op_condition,
			pre_op_investigation,
			elective_emergency,
			...restValues
		} = values;
		const url = `labour-management/anaesthetic`;
		const data = {
			...restValues,
			spontaneous: spontaneous.name,
			findings: preoperative_findings.name,
			recreational_drugs: recreational_drugs.name,
			anaesthetic_technique: anaesthetic_technique.name,
			remarks: remarks.name,
			reversal: reversal.name,
			post_op_condition: post_op_condition.name,
			pre_op_investigations: pre_op_investigation,
			emergency: elective_emergency,
			enrollment_id,
			patient_id: patient.id,
		};

		const rs = await request(url, 'POST', true, data);
		if (rs.success) {
			console.log('success:- ', rs.data);
			notifySuccess('Form saved successfully');
			closeModal();
		} else {
			console.log('success:- ', rs.message);
			notifyError('Form submission failed');
		}
	};

	return (
		<div className="onboarding-modal modal fade animated show" role="dialog" style={{ display: 'block' }}>
			<div className="modal-dialog modal-content modal-centered modal-scroll" style={{ maxWidth: '1024px' }}>
				<div className="modal-content text-center">
					<button aria-label="Close" className="close" type="button" onClick={closeModal}>
						<span className="os-icon os-icon-close"></span>
					</button>
					<div className="onboarding-content with-gradient">
						<h4 className="onboarding-title">Anaesthetic Form</h4>
						{/* Form starts */}
						<div className="form-block element-box">
							<Form
								onSubmit={onSubmit}
								validate={values => {
									const errors = {};
									if (!values.surgeons) {
										errors.surgeons = 'Please Enter Surgeons';
									}
									if (!values.anaesthesist) {
										errors.anaesthesist = 'Please Enter Anaesthesist';
									}
									if (!values.scheduled_surgery) {
										errors.scheduled_surgery = 'Please Enter Procedure';
									}
									if (!values.anaesthetic_technique) {
										errors.anaesthetic_technique = 'Please Enter Anaesthetic Technique';
									}
									if (!values.remarks) {
										errors.remarks = 'Please Select Remarks';
									}
									if (!values.spontaneous) {
										errors.spontaneous = 'Please Select Sponteneous Respiration';
									}
									if (!values.remarks) {
										errors.remarks = 'Please Enter Remarks';
									}
									return errors;
								}}
							>
								{/* Form LOgic */}
								{({ handleSubmit, submitting, values, submitError }) => (
									<form onSubmit={handleSubmit}>
										{submitError && (
											<div
												className="alert alert-danger"
												dangerouslySetInnerHTML={{
													__html: `<strong>Error!</strong> ${submitError}`,
												}}
											/>
										)}

										<div className="row">
											<div className="form-group col-sm-6">
												<label>Patient</label>
												<input type="text" className="form-control" disabled value={patientname(patient)} />
											</div>
											<div className="col-sm-6">
												<div className="form-group">
													<label>Surgeons</label>
													<Field name="surgeons" className="form-control" component="input" type="text" placeholder="Enter Surgeon Name" />
													<Error name="surgeons" />
												</div>
											</div>
										</div>
										{/* Next row */}
										<div className="row">
											<div className="col-sm-4">
												<div className="form-group">
													<label>Anaesthesist</label>
													<Field name="anaesthesist" className="form-control" component="input" type="text" placeholder="Enter Anaesthesist Name" />
													<Error name="anaesthesist" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Scheduled Surgery</label>
													<Field name="scheduled_surgery" className="form-control" component="input" type="text" placeholder="" />
													<Error name="scheduled_surgery" />
												</div>
											</div>
											<div className="col-sm-4">
												<label>Elective Emergency</label>
												<Field name="elective_emergency" className="form-control" component="input" type="text" />
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<div className="form-group">
													<label>Preoprative Findings</label>
													<Field name="preoperative_findings" component={ReactSelectAdapter} options={Findings} />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Recreational Drugs</label>
													<Field name="recreational_drugs" component={ReactSelectAdapter} options={RecreationalDrugs} />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Premedication</label>
													<Field name="premedication" className="form-control" component="input" type="text" />
													<Error name="premedication" />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-3">
												<div className="form-group">
													<label>Pre-op investigations</label>
													<Field
														name="pre_op_investigation"
														className="form-control"
														component="input"
														type="text"
														placeholder="Enter Pre- op Investigations"
													/>
													<Error name="pre_op_investigation" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>Blood Sugar</label>
													<Field name="blood_sugar" className="form-control" component="input" type="text" placeholder="Enter blood Sugar" />
													<Error name="blood_sugar" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>Viral Screening</label>
													<Field name="viral_screening" className="form-control" component="input" type="text" placeholder="Enter viral screening" />
													<Error name="viral_screening" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>Analgesia</label>
													<Field name="analgesia" className="form-control" component="input" type="text" placeholder="Enter Analgesia" />
													<Error name="analgesia" />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-4">
												<div className="form-group">
													<label>Anaesthetic Technique</label>
													<Field name="anaesthetic_technique" component={ReactSelectAdapter} options={AnaestheticTechnique} />

													<Error name="anaesthetic_technique" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Induction</label>
													<Field name="induction" className="form-control" component="input" type="text" />
													<Error name="induction" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Intubation</label>
													<Field name="intubation" className="form-control" component="input" type="text" />
													<Error name="intubation" />
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-sm-3">
												<div className="form-group">
													<label>Maintenance</label>
													<Field name="maintenance" className="form-control" component="input" type="text" />
													<Error name="maintenance" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>Inhalation/Gases</label>
													<Field name="inhalation" className="form-control" component="input" type="text" />
													<Error name="inhalation" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>Other Drugs</label>
													<Field name="other_drugs" className="form-control" component="input" type="text" />
													<Error name="other_drugs" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>Regional</label>
													<Field name="regional" className="form-control" component="input" type="text" />
													<Error name="regional" />
												</div>
											</div>
										</div>
										<div className="row">
											<div className="col-sm-3">
												<div className="form-group">
													<label>Remarks</label>
													<Field name="remarks" component={ReactSelectAdapter} options={RemarkOptions} />

													<Error name="remarks" />
												</div>
											</div>
											{
												// console.log(values)
												values?.remarks?.name === 'BLD' && (
													<>
														<div className="col-sm-3">
															<div className="form-group">
																<label>LOSS</label>
																<Field name="loss" className="form-control" component="input" type="text" />
																<Error name="loss" />
															</div>
														</div>
														<div className="col-sm-3">
															<div className="form-group">
																<label>COLOUR</label>
																<Field name="colour" className="form-control" component="input" type="text" />
																<Error name="colour" />
															</div>
														</div>
													</>
												)
											}
											<div className="col-sm-3">
												<div className="form-group">
													<label>Spontaneous Respiration</label>
													<Field name="spontaneous" component={ReactSelectAdapter} options={YesOrNo} />

													<Error name="spontaneous" />
												</div>
											</div>
										</div>

										<h6>Ventilator Settings</h6>
										<div className="row">
											<div className="col-sm-3">
												<div className="form-group">
													<label>MV</label>
													<Field name="mv" className="form-control" component="input" type="text" />
													<Error name="mv" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>RR</label>
													<Field name="rr" className="form-control" component="input" type="text" />
													<Error name="rr" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>I:E</label>
													<Field name="ie" className="form-control" component="input" type="text" />
													<Error name="ie" />
												</div>
											</div>
											<div className="col-sm-3">
												<div className="form-group">
													<label>PRESS</label>
													<Field name="press" className="form-control" component="input" type="text" />
													<Error name="press" />
												</div>
											</div>
										</div>

										<h6>Fluid</h6>
										<div className="row">
											<div className="col-sm-4">
												<div className="form-group">
													<label>N/S</label>
													<Field name="n_s" className="form-control" component="input" type="text" />
													<Error name="n_s" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>5% DEXTROSE</label>
													<Field name="dextrose" className="form-control" component="input" type="text" />
													<Error name="dextrose" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>4.3%</label>
													<Field name="four_three_percent" className="form-control" component="input" type="text" />
													<Error name="four_three_percent" />
												</div>
											</div>
										</div>

										<h6>Other Drugs</h6>
										<div className="row">
											<div className="col-sm-4">
												<div className="form-group">
													<label>Reversal</label>
													<Field name="reversal" component={ReactSelectAdapter} options={ReversalOptions} />
													<Error name="reversal" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Post-Op Condition</label>
													<Field name="post_op_condition" component={ReactSelectAdapter} options={PostOp_ConditionOptions} />
													<Error name="post_op_condition" />
												</div>
											</div>
											<div className="col-sm-4">
												<div className="form-group">
													<label>Transfer To</label>
													<Field name="transfer_to" className="form-control" component="input" type="text" />
													<Error name="transfer_to" />
												</div>
											</div>
										</div>

										<div className="row">
											<div className="col-sm-12 text-right">
												<button className="btn btn-primary" disabled={submitting} type="submit">
													Save
												</button>
											</div>
										</div>
									</form>
								)}

								{/* Form ends */}
							</Form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AnaestheticForm;
