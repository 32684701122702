export function hasCloseIVFPermission(permissions) {
	return permissions.find(p => p === 'close-ivf');
}
export function hasCancelIVFPermission(permissions) {
	return permissions.find(p => p === 'cancel-ivf');
}
export function hasDiscontinueIVFPermission(permissions) {
	return permissions.find(p => p === 'discontinue-ivf');
}
export function hasContinueIVFPermission(permissions) {
	return permissions.find(p => p === 'continue-ivf');
}
