/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import upperCase from 'lodash.uppercase';
import startCase from 'lodash.startcase';
import capitalize from 'lodash.capitalize';

import topimg from '../assets/images/company5.png';
import { toggleProfile, toggleMode, toggleMenu, toggleFullscreen, signOut } from '../actions/user';
import { setConnection } from '../actions/general';
import { newChatMesage, setActiveUsers, setChatMessageRead, setRoomId, setChatId, setUnreadChats, setGroupedChats, setUnread } from '../actions/chat';
import SearchPatient from './SearchPatient';
import { staffname, parseAvatar, request, patientname } from '../services/utilities';
import SSRStorage from '../services/storage';
import { TOKEN_COOKIE, USER_RECORD, CK_ENCOUNTER, SIDE_PANEL } from '../services/constants';
import { disconnectSocket, emitChat, disconnectChatSocket } from '../services/socket';
import { messageService } from '../services/message';
import SoundeComp from './SoundeComp';
import { notifyCustom } from '../services/notify';
import moment from 'moment';

const storage = new SSRStorage();

class TopBar extends Component {
	state = {
		hover_settings: false,
		hover_unread: false,
		focus: false,
		style: { display: 'none' },
		count: 0,
		profile_image: null,
		triggerSound: 0,
		chatType: '',
	};

	doUpdateChats = newChat => {
		const { unreadChats: chats, unread, setUnread, setUnreadChats } = this.props;
		const temp = [...chats];

		const index = temp.findIndex(chat => chat.id === newChat.sender_id);
		if (index > -1) {
			temp[index].message.unshift(newChat);
		} else {
			const newChats = {
				profile_pic: null,
				id: newChat.sender_id,
				first_name: newChat.sender_first_name,
				last_name: newChat.sender_last_name,
				alias: newChat.alias,
				message: [newChat],
			};
			temp.push(newChats);
		}

		setUnreadChats(temp);
		setUnread(unread + 1);
	};

	componentDidMount() {
		const image = localStorage.getItem('STAFFIMAGE');
		this.setState({ profile_image: image });

		// chat socket subscription
		this.subscription = messageService.getMessage().subscribe(message => {
			const { type, data } = message.text;
			// console.log('message', message.text);
			if (type === 'new-chat-message') {
				const { chatId, roomId, groupedChats, setGroupedChats } = this.props;

				if (data.sender_id !== this.props.profile.details.id) {
					// TODO: check if chat is open and if not, update unread chats
					// if (!data.room_id) {
					// this.props.newChatMesage();
					// }

					// if chat is not open
					if (data.sender_id !== chatId) {
						// update unread chats
						this.doUpdateChats(data);

						// play sound
						this.setState({ chatType: 'chat' });
						this.handleTriggerSound(data.sender_first_name, data.body);
					}
				}

				if (data.recipient_id === chatId || data.sender_id === chatId) {
					if (data.room_id && data.room_id !== roomId) {
						return;
					}
					const date = new Date().toDateString();
					data.createdAt = new Date();

					const temp = [...groupedChats];
					const index = temp.findIndex(group => group.date === date);
					if (index === -1) {
						temp.push({
							date,
							chats: [data],
						});
					} else {
						temp[index].chats.push(data);
					}
					setGroupedChats(temp);
				}
			}

			if (type === 'active_users') {
				this.props.setActiveUsers(data.users);
			}

			if (type === 'new-support-message') {
				if (data.sender_id !== this.props.profile.details.id) {
					this.setState({ chatType: 'support' });
					this.handleTriggerSound(data.sender_first_name, data.body);
				}
			}

			if (type === 'new-support-reply-message') {
				if (data.sender_id !== this.props.profile.details.id) {
					this.setState({ chatType: 'support' });
					this.handleTriggerSound(data.sender_first_name, data.body);
				}
			}

			if (type === 'lab-result') {
				console.log('lab-result', data);
				const requestTime = moment(data.requestTime);
				const approveTime = moment(data.approveTime);

				const duration = moment.duration(approveTime.diff(requestTime));

				let output;

				if (duration.days() > 0) {
					output = `${duration.days()}days ${duration.hours()}hours ${duration.minutes()}minutes`;
				} else if (duration.hours() > 0) {
					output = `${duration.hours()}hours ${duration.minutes()}minutes`;
				} else {
					output = `${duration.minutes()}minutes`;
				}
				notifyCustom(
					<>
						<h6 className="text-white">Lab result ready for {data.request.item.labTest.name}</h6>
						<p>{patientname(data.request.patient, true)}</p>

						<p>Duration: {output}</p>
					</>,
					{
						position: 'top-right',
						autoClose: false,
						// icon: '💬',
						style: {
							wordBreak: 'break-word',
							background: '#1e62eb',
							color: '#fff',
							width: '440px',
							marginLeft: '-129px',
						},
						type: 'success',
					}
				);
			}

			if (type === 'message-read') {
				const { count, messages } = data;
				const { setUnread, setUnreadChats } = this.props;
				setUnread(count);
				setUnreadChats(messages);
			}
		});
	}

	componentWillUnmount() {
		this.subscription.unsubscribe();
	}

	getCoords = elem => {
		return { top: 12, right: 100 };
	};

	openProfile = () => {
		const { profile } = this.props;
		const info = { staff: profile, type: 'staff' };
		this.props.toggleProfile(true, info);
	};

	getProfileImage = async () => {
		const image = await localStorage.getItem('STAFFIMAGE');
		return image;
	};

	toggleSettings = () => {
		const { hover_settings } = this.state;
		this.setState({ hover_settings: !hover_settings });
	};

	doToggleMode = () => {
		this.props.toggleMode();
	};

	setShowUnread = bool => {
		this.setState({ hover_unread: bool });
	};

	doToggleMenu = () => {
		this.props.toggleMenu();
	};

	doToggleFullscreen = () => {
		this.props.toggleFullscreen();
	};

	onFocus = () => {
		const style = { display: 'block' };
		const coords = this.getCoords(this.refs.searchBox);
		this.setState({ style: { ...style, ...coords }, focus: true });
	};

	onExit = () => {
		const style = { display: 'none' };
		this.setState({ style, focus: false });
	};

	doLogout = async () => {
		const { profile } = this.props;

		if (profile.role.slug === 'doctor') {
			await request(`hr/staffs/unset-room/${profile.details.id}`, 'GET', true);
			storage.removeItem('ACTIVE:ROOM');
		}

		emitChat('logout', { userId: profile.details.id });

		storage.removeItem(USER_RECORD);
		storage.removeItem(SIDE_PANEL);
		storage.removeItem(TOKEN_COOKIE);
		storage.removeItem(CK_ENCOUNTER);

		this.props.signOut();

		disconnectSocket();
		disconnectChatSocket();
		this.props.setConnection(false);

		this.props.history.push('/');
	};

	doTriggerSound = () => {
		this.setState({ triggerSound: this.state.triggerSound + 1 });
	};

	handleTriggerSound = (from, message) => {
		this.doTriggerSound();
		notifyCustom(
			<>
				<h6>{from} sent you a message</h6>
				<p>{message.length > 20 ? message.substring(0, 20) + '...' : message}</p>
			</>,
			{
				position: 'top-right',
				autoClose: 5000,
				icon: '💬',
				style: {
					wordBreak: 'break-word',
					background: '#00d9ff',
					// fontWeight: '700',
				},
			}
		);
	};

	doGoToChat = chat => {
		const chatId = chat.id;
		this.props.setChatId(chatId);
		this.props.setRoomId(null);
		this.props.history.push('/my-account/chat');
	};

	render() {
		const { role, location, profile, unread, unreadChats } = this.props;
		const paths = location.pathname.split('/');
		const title = paths.length > 1 ? paths[1] : '';
		const sub_title = paths.length > 2 ? paths[2] : '';
		const { hover_settings, hover_unread, focus, style, count, profile_image, triggerSound, chatType } = this.state;

		return (
			<div className="top-bar color-scheme-transparent">
				{title && title !== '' && (
					<div className="fancy-selector-w">
						<div className="fancy-selector-current">
							<div className="top-icon shadowless text-white pr-0" style={{ cursor: 'pointer' }} onClick={this.doToggleMenu}>
								<i className="os-icon os-icon-hamburger-menu-1" />
							</div>
							<div className="fs-img shadowless pr-0">
								<img alt="" src={topimg} />
							</div>
							<div className="fs-main-info">
								<div className="fs-name">
									<span>{`${upperCase(title.replace('-mgt', ''))} MANAGEMENT`}</span>
								</div>
								{sub_title && (
									<div className="fs-sub">
										<span>{startCase(sub_title)}</span>
									</div>
								)}
							</div>
						</div>
					</div>
				)}
				<div className="top-menu-controls" ref="searchBox">
					{focus && <SearchPatient style={style} onExit={this.onExit} focus={focus} />}
					<div className="element-search autosuggest-search-activator">
						<input placeholder="Find patient..." type="text" onFocus={this.onFocus} />
					</div>
					<div className="messages-notifications os-dropdown-trigger os-dropdown-position-left">
						{/* <i className="os-icon os-icon-mail-14" /> */}
						<i className="icon-feather-bell" />
						{count > 0 && <div className="new-messages-count">{{ count }}</div>}
					</div>
					<div
						className={`messages-notifications os-dropdown-trigger os-dropdown-position-left ${hover_unread ? 'over' : ''} `}
						onMouseOver={() => this.setShowUnread(true)}
						onMouseLeave={() => this.setShowUnread(false)}
					>
						<i className="os-icon os-icon-mail-14" />
						{unread > 0 && <div className="new-messages-count">{unread > 99 ? '99+' : unread}</div>}
						<SoundeComp playSound={triggerSound} chatType={chatType} />
						<div className="os-dropdown light message-list">
							<ul>
								{unreadChats.length > 0 ? (
									unreadChats?.map((chat, index) => {
										return (
											<li key={index + chat.first_name}>
												<a onClick={() => this.doGoToChat(chat)}>
													<div className="user-avatar-w">
														<img alt="" src={chat.profile_pic ?? parseAvatar(chat?.profile_pic)} />
													</div>
													<div className="message-content">
														<h6 className="message-from">{chat?.alias ?? chat.first_name + ' ' + chat.last_name}</h6>
														<h6 className="message-title">{chat.message[0].body}</h6>
													</div>
												</a>
											</li>
										);
									})
								) : (
									<li>
										<a href="#">
											<div className="message-content">
												<h6 className="message-from">No unread messages</h6>
											</div>
										</a>
									</li>
								)}
							</ul>
						</div>
					</div>
					{/* <div className="messages-notifications os-dropdown-trigger os-dropdown-position-right">
						<i className="os-icon os-icon-zap" />
						<div className="new-messages-count">4</div>
					</div> */}
					<div
						className={`top-icon top-settings os-dropdown-trigger os-dropdown-position-left ${hover_settings ? 'over' : ''}`}
						onClick={this.toggleSettings}
					>
						<i className="os-icon os-icon-ui-46" />
						<div className="os-dropdown">
							<div className="icon-w">
								<i className="os-icon os-icon-ui-46" />
							</div>
							<ul>
								<li>
									<a onClick={this.doToggleMode}>
										<i className="os-icon os-icon-ui-49" />
										<span>Dark Mode ON/OFF</span>
									</a>
								</li>
								{/* <li>
									<a onClick={this.doToggleFullscreen}>
										<i className="os-icon os-icon-grid-10" />
										<span>Fullscreen ON/OFF</span>
									</a>
								</li> */}
							</ul>
						</div>
					</div>
					<div className="logged-user-w">
						<div className="logged-user-i">
							<div className="avatar-w">
								<img alt="" src={profile_image !== null ? profile_image : parseAvatar(profile?.details?.profile_pic)} />
							</div>
							<div className="logged-user-menu color-style-bright">
								<div className="logged-user-avatar-info">
									<div className="avatar-w">
										<img alt="" src={profile_image !== null ? profile_image : parseAvatar(profile?.details?.profile_pic)} />
									</div>
									<div className="logged-user-info-w">
										<div className="logged-user-name">{staffname(profile?.details)}</div>
										<div className="logged-user-role">{role !== 'patient' ? capitalize(role) : 'Doctor'}</div>
									</div>
								</div>
								<div className="bg-icon">
									<i className="os-icon os-icon-wallet-loaded" />
								</div>
								<ul>
									<li>
										<a onClick={this.openProfile}>
											<i className="os-icon os-icon-user-male-circle2" />
											<span>Profile Details</span>
										</a>
									</li>
									<li>
										<a onClick={this.doLogout} to="/logout" className="pointer">
											<i className="os-icon os-icon-signs-11" />
											<span>Logout</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		profile: state.user.profile,
		unread: state.chat.unread,
		unreadChats: state.chat.unreadChats,
		chatId: state.chat.chatId,
		roomId: state.chat.roomId,
		groupedChats: state.chat.groupedChats,
	};
};

export default withRouter(
	connect(mapStateToProps, {
		toggleProfile,
		toggleMode,
		toggleMenu,
		toggleFullscreen,
		signOut,
		setConnection,
		newChatMesage,
		setActiveUsers,
		setChatMessageRead,
		setChatId,
		setRoomId,
		setUnreadChats,
		setGroupedChats,
		setUnread,
	})(TopBar)
);
