import React, { useCallback, useEffect, useState } from 'react';
import TableLoading from '../../TableLoading';
import { formatDate, itemRender, request } from '../../../services/utilities';
import Pagination from 'antd/lib/pagination';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';
import { startBlock, stopBlock } from '../../../actions/redux-block';
import { notifyError } from '../../../services/notify';

const FeedingChart = ({ type, itemId, patient }) => {
	const [loading, setLoading] = useState(true);
	const [feedingcharts, setFeedingCharts] = useState([]);
	const [meta, setMeta] = useState({
		currentPage: 1,
		itemsPerPage: 10,
		totalPages: 0,
	});

	const dispatch = useDispatch();

	const fetchFeedingCharts = useCallback(
		async page => {
			try {
				dispatch(startBlock());
				const p = page || 1;
				const url = `feeding-charts?patient_id=${patient.id}&page=${p}&limit=10&type=${type}&item_id=${itemId}`;
				const rs = await request(url, 'GET', true);
				const { result, ...meta } = rs;
				setFeedingCharts(result);
				setMeta(meta);

				dispatch(stopBlock());
			} catch (error) {
				console.log(error);
				dispatch(stopBlock());
				notifyError('error fetching fluid charts');
			}
		},
		[dispatch, itemId, patient, type]
	);

	useEffect(() => {
		if (loading) {
			fetchFeedingCharts();
			setLoading(false);
		}
	}, [fetchFeedingCharts, loading]);

	return (
		<div className="col-sm-12">
			<div className="element-wrapper">
				<h6 className="element-header">Feeding Chart</h6>
				<div className="element-box p-3 m-0">
					{loading ? (
						<TableLoading />
					) : (
						<div className="table-responsive">
							<table className="table table-bordered table-md table-v2 table-striped">
								<thead>
									<tr>
										<th>Date</th>
										<th>Time</th>
										<th>Type Of Feed</th>

										<th>Remarks</th>
									</tr>
								</thead>
								<tbody>
									{feedingcharts.map((item, i) => {
										return (
											<tr key={i}>
												<td>{formatDate(item.createdAt, 'D-MMM-YYYY')}</td>
												<td>{formatDate(item.createdAt, 'h:mm A')}</td>
												<td>{item.feed || '--'}</td>

												<td>{item.remarks || '--'}</td>
											</tr>
										);
									})}

									{isEmpty(feedingcharts) && (
										<tr>
											<td colSpan="9" className="text-center">
												Feeding charts is unavailable
											</td>
										</tr>
									)}
								</tbody>
							</table>

							{meta && (
								<div className="pagination pagination-center mt-4">
									<Pagination
										current={parseInt(meta.currentPage, 10)}
										pageSize={parseInt(meta.itemsPerPage, 10)}
										total={parseInt(meta.totalPages, 10)}
										showTotal={total => `Total ${total} items`}
										itemRender={itemRender}
										// onChange={current => onNavigatePage(current)}
										showSizeChanger={false}
									/>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default FeedingChart;
