import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PharmacyMenu = () => {
	const staff = useSelector(state => state.user.profile);

	const canViewPharmacyReports = user => {
		switch (user.role.slug) {
			case 'pharmacy-supervisor':
				return true;
			default:
				return false;
		}
	};

	return (
		<>
			<li>
				<Link to="/pharmacy">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Dashboard</span>
				</Link>
			</li>
			<li>
				<Link to="/pharmacy/new-request">
					<div className="icon-w">
						<div className="os-icon os-icon-plus-circle" />
					</div>
					<span>New Request</span>
				</Link>
			</li>
			<li>
				<Link to="/pharmacy/billing">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Pay Point</span>
				</Link>
			</li>
			{/* <li>
				<Link to="/pharmacy/admitted-bill">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Admission Bill</span>
				</Link>
			</li> */}
			<li>
				<Link to="/pharmacy/inventory">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Inventory</span>
				</Link>
			</li>
			<li>
				<Link to="/pharmacy/vendors">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Vendors</span>
				</Link>
			</li>
			<li>
				<Link to="/pharmacy/reports">
					<div className="icon-w">
						<div className="os-icon os-icon-layers" />
					</div>
					<span>Reports</span>
				</Link>
			</li>
			{['pharmacy-supervisor'].includes(staff.role.slug) && (
				<li>
					<Link to="/hmo/schemes">
						<div className="icon-w">
							<div className="os-icon os-icon-layers" />
						</div>
						<span>HMO Schemes</span>
					</Link>
				</li>
			)}
			{canViewPharmacyReports(staff) && (
				<>
					<li className="sub-header">
						<span>REPORTS</span>
					</li>
					<li>
						<Link to="/report/pharmacy">
							<div className="icon-w">
								<div className="os-icon os-icon-layers" />
							</div>
							<span>Pharmacy</span>
						</Link>
					</li>
				</>
			)}
		</>
	);
};

export default PharmacyMenu;
