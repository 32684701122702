import React from 'react';
import { Link, withRouter } from 'react-router-dom';

const checkHash = (hash, path) => hash.find(h => h === path);

const LabourProfileMenu = ({ location }) => {
	const hash = location.hash.split('#');

	return (
		<div className="top-bar color-scheme-light">
			<ul>
				<li className={checkHash(hash, 'notes') ? 'active' : ''}>
					<Link to={`${location.pathname}#notes`} className="pointer">
						Notes
					</Link>
				</li>
				<li className={checkHash(hash, 'risk-assessments') ? 'active' : ''}>
					<Link to={`${location.pathname}#risk-assessments`} className="pointer">
						Risk Assessments
					</Link>
				</li>
				<li className={checkHash(hash, 'measurements') ? 'active' : ''}>
					<Link to={`${location.pathname}#measurements`} className="pointer">
						Measurements
					</Link>
				</li>
				<li className={checkHash(hash, 'vitals') ? 'active' : ''}>
					<Link to={`${location.pathname}#vitals`} className="pointer">
						Partograph
					</Link>
				</li>
				<li className={checkHash(hash, 'lab') ? 'active' : ''}>
					<Link to={`${location.pathname}#lab`} className="pointer">
						Lab
					</Link>
				</li>
				<li className={checkHash(hash, 'procedure') || checkHash(hash, 'procedure-request') ? 'active' : ''}>
					<Link to={`${location.pathname}#procedure`} className="pointer">
						Procedure
					</Link>
				</li>
				<li className={checkHash(hash, 'anaesthetic') ? 'active' : ''}>
					<Link to={`${location.pathname}#anaesthetic`} className="pointer">
						Anaesthesia
					</Link>
				</li>
				<li className={checkHash(hash, 'delivery') ? 'active' : ''}>
					<Link to={`${location.pathname}#delivery`} className="pointer">
						Delivery Process
					</Link>
				</li>
				<li className={checkHash(hash, 'new-born') ? 'active' : ''}>
					<Link to={`${location.pathname}#new-born`} className="pointer">
						New Born Information
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default withRouter(LabourProfileMenu);
