import {
	ADD_CHAT_GROUP,
	NEW_CHAT_MESSAGE,
	RESET_CHAT_MESSAGE,
	SET_ACTIVE_USERS,
	SET_CHAT_GROUPS,
	SET_CHAT_ID,
	SET_CHAT_MESSAGE_READ,
	SET_GROUPED_CHATS,
	SET_IS_SUPPORT_CHAT,
	SET_ROOM_ID,
	SET_SELECTED_ROOM,
	SET_SUPPORT_CHATS,
	SET_UNREAD,
	SET_UNREAD_CHATS,
	SET_USERS,
	TOGGLE_CHAT_SIDEBAR,
} from './types';

export const setUsers = data => {
	return {
		type: SET_USERS,
		payload: data,
	};
};

export const newChatMesage = () => {
	return {
		type: NEW_CHAT_MESSAGE,
	};
};

export const resetChatMessage = () => {
	return {
		type: RESET_CHAT_MESSAGE,
	};
};

export const setUnread = data => {
	return {
		type: SET_UNREAD,
		payload: data,
	};
};

export const setUnreadChats = data => {
	return {
		type: SET_UNREAD_CHATS,
		payload: data,
	};
};

export const setActiveUsers = data => {
	return {
		type: SET_ACTIVE_USERS,
		payload: data,
	};
};

export const setChatMessageRead = () => {
	return {
		type: SET_CHAT_MESSAGE_READ,
	};
};

export const setChatGroups = data => {
	return {
		type: SET_CHAT_GROUPS,
		payload: data,
	};
};

export const addChatGroup = data => {
	return {
		type: ADD_CHAT_GROUP,
		payload: data,
	};
};

export const toggleChatSidebar = () => {
	return {
		type: TOGGLE_CHAT_SIDEBAR,
	};
};

export const setChatId = data => {
	return {
		type: SET_CHAT_ID,
		payload: data,
	};
};

export const setRoomId = data => {
	return {
		type: SET_ROOM_ID,
		payload: data,
	};
};

export const setSelectedRoom = data => {
	return {
		type: SET_SELECTED_ROOM,
		payload: data,
	};
};

export const setSupportChats = data => {
	return {
		type: SET_SUPPORT_CHATS,
		payload: data,
	};
};

export const setIsSupportChat = data => {
	return {
		type: SET_IS_SUPPORT_CHAT,
		payload: data,
	};
};

export const setGroupedChats = data => {
	return {
		type: SET_GROUPED_CHATS,
		payload: data,
	};
};
